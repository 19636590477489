import React from "react";
import NavButtons from "../../navBtns";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import MyVerticallyCenteredModal from "../../modals/successJobModal";
import "./style.css";
import axios from "axios";
import AddSample from "./addNewSample";
import format from "date-fns/format";
import addWeeks from "date-fns/add_weeks";
import { endpoint } from "../../../utils/endpoint";

class AddNewJobOrder extends React.Component {
  state = {
    navBtnColor: "",
    modalShow: false,
    locationId: "None",
    jobTypeId: "3",
    dateStarted: format(new Date(), "YYYY-MM-DD"),
    todaysDate: format(new Date(), "YYYY-MM-DD"),
    finishDate: format(addWeeks(new Date(), 2), "YYYY-MM-DD"),
    jobNumber: "",
    jobLocations: [],
    jobOrderId: 0,
    notes: "",
    dateEntered: format(new Date(), "YYYY-MM-DD"),
    stone: null,
    stonePrice: null,
    band: null,
    jobHistoryDate: format(new Date(), "YYYY-MM-DD"),
    prevJob: ""
  };

  componentDidMount() {
    this.grabJobLocations();
    this.setState({ navBtnColor: "#454d55" });
  }

  handleLocationInput = event => {
    let locationName = event.target.value;
    this.setState({
      locationName
    });
  };


  submitLocation = () => {
    this.createNewJob();
    this.setState({ modalShow: true });
  };

  /**
   * incrementJobNumber used in grabJoblocations
   */
  incrementJobNumber = jobNum => {
    const jNumber = parseInt(jobNum.slice(1)) + 1;
    const jPrefix = jobNum.slice(0, 1);
    return jPrefix + jNumber;
  };

  grabJobLocations = async () => {
    let res = await axios.get(endpoint + "/grabAllLocations/1");
    let jobLocations = res.data;
    let resTwo = await axios.get(endpoint + "/grabLastEnteredJob");
    let lastJob = resTwo.data;
    let jobNumber;
    if (lastJob.length) {
      jobNumber = this.incrementJobNumber(lastJob[0].job_number);
    } else {
      jobNumber = "J1";
    }
    this.setState({
      jobLocations,
      jobNumber
    });
  };

  handleInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  createNewJob = () => {
    this.setState({ modalShow: true });
    console.log(this.state);
    if (this.state.jobTypeId === "3") {
      axios
        .post(endpoint + "/createSampleJobOrder", {
          dateStarted: this.state.dateStarted,
          finishDate: this.state.finishDate,
          jobNumber: this.state.jobNumber,
          locationId: this.state.locationId,
          dateEntered: this.state.dateEnteredLocation,
          jobTypeId: this.state.jobTypeId,
          notes: this.state.notes,
          style: this.state.style,
          description: this.state.description,
          stone: this.state.stone,
          stonePrice: this.state.stonePrice,
          band: this.state.band,
          jobHistoryDate: this.state.todaysDate
        })
        .then(response => {
          this.setState({
            locationId: "None",
            dateEntered: this.state.dateEnteredLocation,
            jobTypeId: "3",
            notes: "",
            style: "",
            description: "",
            stone: "",
            stonePrice: "",
            band: "",
            prevJob: response.data.job_number
          });
          this.grabJobLocations();
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      axios
        .post(endpoint + "/createJobOrder", {
          dateStarted: this.state.dateStarted,
          finishDate: this.state.finishDate,
          jobNumber: this.state.jobNumber,
          locationId: this.state.locationId,
          jobTypeId: this.state.jobTypeId,
          notes: this.state.notes,
          jobHistoryDate: this.state.todaysDate
        })
        .then(response => {
          this.setState({
            locationId: "None",
            dateEntered: this.state.dateEnteredLocation,
            jobTypeId: "3",
            notes: "",
            style: "",
            description: "",
            stone: "",
            stonePrice: "",
            band: "",
            prevJob: response.data.job_number
          });
          this.grabJobLocations();
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  modalClose = () => this.setState({ modalShow: false });
  render() {
    return (
      <React.Fragment>
        <NavButtons toggleJobsBtn={this.state.navBtnColor} />
        <div className="containerDiv">
          <div className="inputDivOne">
            <div className="dateInputDiv">
              <label className="jobsLabel">Date Started</label>
              <input
                className="dateInputBox jobsInput"
                type="date"
                autoComplete="off"
                name="dateStarted"
                onChange={this.handleInput}
                value={this.state.dateStarted}
              />
            </div>
            <div className="dateInputDiv">
              <label className="jobsLabel">Estimated Date Complete</label>
              <input
                className="dateInputBox jobsInput"
                type="date"
                name="finishDate"
                autoComplete="off"
                onChange={this.handleInput}
                value={this.state.finishDate}
              />
            </div>
          </div>

          <div className="inputDivOne">
            <div className="jobInputDiv">
              <label className="jobsLabel">Job Number</label>
              <FormControl
                className="jobsInput"
                type="text"
                autoComplete="off"
                value={this.state.jobNumber}
                name="jobNumber"
                onChange={this.handleInput}
                disabled={true}
              />
            </div>

            <div className="jobInputDiv">
              <label className="jobsLabel ">Job Type</label>
              <Form.Group
                controlId="exampleForm.ControlSelect1"
                className="test"
              >
                <Form.Control
                  as="select"
                  className="jobsInput"
                  defaultValue="Job"
                  style={{ border: "solid 2px #bba3d0" }}
                  name="jobTypeId"
                  value={this.state.jobTypeId}
                  onChange={this.handleInput}
                >
                  <option value="3">Sample</option>
                  <option value="1">Repair</option>
                  <option value="2">Custom</option>
                  <option value="4">Part</option>
                </Form.Control>
              </Form.Group>
            </div>
          </div>
          <div className="inputDivOne">
            <div className="jobInputDiv">
              <label className="jobsLabel">Notes</label>
              <FormControl
                className="jobsInput"
                type="text"
                placeholder=""
                name="notes"
                autoComplete="off"
                onChange={this.handleInput}
                value={this.state.notes}
              />
            </div>

            <div className="jobInputDiv suggestParent">
              <label className="jobsLabel">Job Location</label>
              <Form.Control
                as="select"
                className="jobsInput"
                defaultValue="Job"
                name="locationId"
                autoComplete="off"
                onChange={this.handleInput}
                value={this.state.locationId}
              >
                <option value={null}>{"None"}</option>
                {this.state.jobLocations.map(item => {
                  return <option value={item.id}>{item.location_title}</option>;
                })}
              </Form.Control>
            </div>
          </div>

          <div>
            {this.state.jobTypeId === "3" ? (
              <AddSample handleChangeProp={this.handleInput} sampleState={{style: this.state.style, description: this.state.description, stone: this.state.stone, stonePrice: this.state.stonePrice, band: this.state.band}} />
            ) : null}
          </div>
        </div>

        <div id="buttonDiv">
          <Button
            variant="outline-secondary"
            id="createJobButton"
            onClick={this.createNewJob}
          >
            Create
          </Button>

          <MyVerticallyCenteredModal
            show={this.state.modalShow}
            onHide={this.modalClose}
            jobNumForQr={this.state.prevJob}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default AddNewJobOrder;
