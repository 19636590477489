import React from "react";
import axios from "axios";
import NavBar from "../../navBtns";
import format from 'date-fns/format';
import {
  FormControl,
  Form,
  Dropdown,
  InputGroup,
  DropdownButton,
  Table,
  Spinner
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.css";
import { FiEdit } from "react-icons/fi";
import AddFinishedCodeModal from "../../modals/addFinishCodeModal";
import print from "print-js";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DataTable from '../../DataTable2.0';
import Tooltipped from "../../tooltip/Tooltipped";
import { endpoint } from "../../../utils/endpoint";
import { HashRouter, Route, Switch } from "react-router-dom";

const thStyle = {
  fontStyle: "italic",
  letterSpacing: 2,
  textTransform: "uppercase"
};

const styles = {
  columnTitle: {
    textAlign: "center",
    padding: "12px 5px"
  },
}

class listJobs extends React.Component {
  state = {
    searchType: "All",
    navBtnColor: "",
    jobsData: [],
    originalData: [],
    editFinishedCodeBool: false,
    modalShow: false,
    jobId: 0,
    currentCheckbox: ""
  };

  printList = () => {
    print("tablePrint", "html");
  };
  modalClose = () => this.setState({ modalShow: false });

  componentDidMount() {
    const checkboxes = document.querySelectorAll("[type=checkbox]");
    this.setState({ navBtnColor: "#454d55" });
    this.handleGrabInfo("All");
  }


  filterOutFinishedJobs = jobsData => {
    const filteredOut = jobsData.filter(jobObj => {
      return jobObj.locationId !== 1;
    });
    return filteredOut;
  };

  filterOutShippedJobs = jobsData => {
    const filteredOut = jobsData.filter(jobObj => {
      return jobObj.locationId !== 2;
    });
    return filteredOut;
  };

  handleGrabInfo = jobType => {
    let jobTypeId;
    switch (jobType) {
      case "All":
        jobTypeId = 0;
        break;
      case "Repair":
        jobTypeId = 1;
        break;
      case "Custom":
        jobTypeId = 2;
        break;
      case "Sample":
        jobTypeId = 3;
        break;
      case "Part":
        jobTypeId = 4;
        break;
      case "Finished":
        jobTypeId = 5;
        break;
      case "Shipped":
        jobTypeId = 6;
        break;
    }

    // set state so that I know what type of search to proceed with.
    this.setState({
      currentCheckbox: "",
      jobTypeId
    });

    if (jobTypeId === 0) {
      axios
        .get(endpoint + `/grabJobOrders`)
        .then(response => {
          let jobsDataOne = this.filterOutFinishedJobs(response.data);
          let jobsData = this.filterOutShippedJobs(jobsDataOne);
          this.setState({
            originalData: jobsData,
            jobsData
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (jobTypeId === 5) {
      this.grabAllFinishedJob();
    }
    else if (jobTypeId === 6) {
      this.grabAllShippedJob();
    } else {
      axios
        .get(endpoint + `/grabAllJobOrders/${jobTypeId}`)
        .then(response => {
          let jobsDataOne = this.filterOutFinishedJobs(response.data);
          let jobsData = this.filterOutShippedJobs(jobsDataOne);

          this.setState({
            originalData: jobsData,

            jobsData: jobsData
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  handleJobType = jobType => {
    this.setState({
      searchValue: ""
    });
    this.setState({
      searchType: jobType
    });

    this.handleGrabInfo(jobType);
  };

  handleCheckbox = e => {
    const { name } = e.target;
    const { currentCheckbox, originalData } = this.state;
    if (currentCheckbox)
      return this.setState({ currentCheckbox: "", jobsData: originalData });

    const jobsDataCopy = [...this.state.jobsData];

    const sortedData = jobsDataCopy.sort((a, b) => {
      if (!a[name]) a[name] = "z";
      if (!b[name]) b[name] = "z";
      if (a[name].toLowerCase() < b[name].toLowerCase()) {
        return -1;
      }
      if (a[name].toLowerCase() > b[name].toLowerCase()) {
        return 1;
      }
      return 0;
    });

    this.setState({ currentCheckbox: name, jobsData: sortedData });
  };

  finishedOrderBy = filterType => {
    axios
      .get(endpoint + `/grabFilteredFinishedJobs/${filterType}`)
      .then(response => {
        let jobsData = response.data;
        this.setState({
          jobsData
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  sampleOrderBy = filterType => {
    axios
      .get(endpoint + `/grabFilteredSampleJobs/${filterType}`)
      .then(response => {
        let jobsDataOne = this.filterOutFinishedJobs(response.data);
        let jobsData = this.filterOutShippedJobs(jobsDataOne);
        this.setState({
          jobsData: jobsData
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  returnJobType = jobTypeId => {
    let jobTypeString;
    switch (jobTypeId) {
      case 1:
        jobTypeString = "Repair";
        break;
      case 2:
        jobTypeString = "Custom";
        break;
      case 3:
        jobTypeString = "Sample";
        break;
      case 4:
        jobTypeString = "Part";
        break;
      case 5:
        jobTypeString = "Finished";
        break;
      default:
        jobTypeString = null;
        break;
    }
    return jobTypeString;
  };

  searchAllJobs = e => {
    this.setState({
      searchValue: e.target.value
    });
    let searchTypeUrl =
      endpoint + `/searchByJobType/${e.target.value}/${this.state.jobTypeId}`;
    let searchAllUrl = endpoint + `/searchAllJobsList/${e.target.value}`;
    let searchFinishedURL =
      endpoint + `/searchAllFinishedJobs/${e.target.value}`;
    let searchShippedURL =
      endpoint + `/searchAllShippedJobs/${e.target.value}`;
    let searchURL;
    if (this.state.jobTypeId === 0) {
      searchURL = searchAllUrl;
    } else if (this.state.jobTypeId === 5) {
      searchURL = searchFinishedURL;
    } else if (this.state.jobTypeId === 6) {
      searchURL = searchShippedURL;
    }
    else {
      searchURL = searchTypeUrl;
    }

    if (e.target.value.length) {
      axios
        .get(searchURL)
        .then(response => {
          let jobsDataOne = this.filterOutFinishedJobs(response.data);
          let jobsData = this.filterOutShippedJobs(jobsDataOne);

          if (this.state.jobTypeId === 5 || this.state.jobTypeId === 6) {
            this.setState({
              jobsData: response.data
            });
          }
          else {
            this.setState({
              jobsData: jobsData
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      this.handleGrabInfo(this.state.searchType);
    }
  };

  grabAllFinishedJob = () => {
    axios
      .get(endpoint + `/grabAllJobsInFinished`)
      .then(response => {
        let finishedJobs = response.data;
        console.log("TEST DataT", finishedJobs);
        this.setState({
          originalData: finishedJobs,
          jobsData: finishedJobs
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  grabAllShippedJob = () => {
    axios
      .get(endpoint + `/grabAllJobsInShipped`)
      .then(response => {
        let shippedJobs = response.data;
        console.log("TEST DataT", shippedJobs);
        this.setState({
          originalData: shippedJobs,
          jobsData: shippedJobs
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  handleUpdateFinishInput = e => {
    this.setState({
      finishedCode: e.target.value
    });
  };
  updateFinishedJob = (fCode, id) => {
    this.setState({
      finishedCode: fCode,
      jobId: id,
      modalShow: true
    });
  };
  render() {
    const { currentCheckbox } = this.state;
    console.log("currentCheckbox", currentCheckbox);
    return (
      <React.Fragment>
        <NavBar toggleJobsBtn={this.state.navBtnColor} />
        <div>
          <Form id="searchJobsFormDiv">
            <InputGroup className="inputWithButton">
              <DropdownButton
                as={InputGroup.Prepend}
                variant="outline-secondary"
                title={this.state.searchType}
                id="input-group-dropdown-1"
              // onSelect={()=> this.handleGrabInfo(this.state.searchType)}
              >
                <Dropdown.Item onClick={() => this.handleJobType("All")}>
                  All
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => this.handleJobType("Repair")}>
                  Repair
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => this.handleJobType("Custom")}>
                  Custom
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => this.handleJobType("Sample")}>
                  Sample
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => this.handleJobType("Part")}>
                  Part
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => this.handleJobType("Finished")}>
                  Finished
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => this.handleJobType("Shipped")}>
                  Shipped
                </Dropdown.Item>
              </DropdownButton>
              <FormControl
                aria-describedby="basic-addon1"
                onChange={this.searchAllJobs}
                placeholder={this.state.searchType === 'All' || this.state.searchType === 'Custom' || this.state.searchType === 'Repair' || this.state.searchType === 'Part' ? 'USE SEARCH BOX BELLOW': "Search by job number" }
                value={this.state.searchValue}
                disabled={this.state.searchType === 'All' || this.state.searchType === 'Custom' || this.state.searchType === 'Repair' || this.state.searchType === 'Part' }
              />
            </InputGroup>
            {/* <Button id="searchJobsButton" onChange={this.searchThis}>Search</Button> */}
            {this.state.searchType === "Sample" ||
              this.state.searchType === "Finished" || this.state.searchType === "Shipped" ? (
                <Dropdown>
                  <Dropdown.Toggle id="sortJobsButton">Sort By</Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className="sortCheckboxDiv">
                      <input
                        ref={input => {
                          this.typeCheckbox = input;
                        }}
                        type="checkbox"
                        name="style"
                        checked={currentCheckbox === "style" || false}
                        disabled={
                          currentCheckbox === "stone" ||
                          currentCheckbox === "description"
                        }
                        onChange={e => this.handleCheckbox(e)}
                      />
                      <label>Style</label>
                    </div>
                    <Dropdown.Divider />
                    <div className="sortCheckboxDiv">
                      <input
                        ref={input => {
                          this.stoneCheckbox = input;
                        }}
                        type="checkbox"
                        name="stone"
                        checked={currentCheckbox === "stone" || false}
                        disabled={
                          currentCheckbox === "style" ||
                          currentCheckbox === "description"
                        }
                        onChange={e => this.handleCheckbox(e)}
                      />
                      <label>Stone</label>
                    </div>
                    <Dropdown.Divider />
                    <div className="sortCheckboxDiv">
                      <input
                        ref={input => {
                          this.descCheckbox = input;
                        }}
                        type="checkbox"
                        name="description"
                        checked={currentCheckbox === "description" || false}
                        disabled={
                          currentCheckbox === "style" ||
                          currentCheckbox === "stone"
                        }
                        onChange={e => this.handleCheckbox(e)}
                      />
                      <label htmlFor="checkbox">Description</label>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              ) : null}
          </Form>
        </div>
        {this.state.jobsData.length ?

          <div>
            {this.state.searchType === "Finished" ? (
              <div className="tableWrapper" id="tablePrint">
                <Table
                  striped
                  bordered
                  hover
                  variant="dark"
                  className="rounded"
                  id="table-to-xls"
                >
                  <thead>
                    <tr>
                      <th width="100px" >
                        <Tooltipped>Finished Code</Tooltipped>
                      </th>
                      <th width="100px" >Job Number</th>
                      <th width="50px" style={styles.columnTitle, currentCheckbox === "style" ? thStyle : null}>
                        <Tooltipped>Style</Tooltipped>
                      </th>
                      <th width="60px" style={styles.columnTitle, currentCheckbox === "stone" ? thStyle : null}>
                        <Tooltipped>Stone</Tooltipped>
                      </th>
                      <th width="50px">
                        <Tooltipped>Stone Price</Tooltipped>
                      </th>
                      <th width="200px" style={styles.columnTitle, currentCheckbox === "description" ? thStyle : null}
                      >
                        <Tooltipped>Description</Tooltipped>
                      </th>
                      <th width="50px">
                        <Tooltipped>Band</Tooltipped>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.jobsData.map(item => {
                      return (
                        <tr>
                          <td>{item.finished_code}</td>
                          <td>
                            <Link
                              style={{ color: "#bba3d0" }}
                              to={{
                                pathname: "/jobInfo",
                                state: {
                                  jobId: item.job_number
                                }
                              }}
                            >
                              {item.job_number}
                            </Link>
                          </td>
                          <td>{item.style !== "z" ? item.style : " "}</td>
                          <td>{item.stone !== "z" ? item.stone : " "}</td>
                          <td>{item.stone_price}</td>
                          <td>
                            {item.description !== "z" ? item.description : " "}
                          </td>
                          <td>{item.band}<button
                            className="editButtons"
                            onClick={() =>
                              this.updateFinishedJob(item.finished_code, item.id)
                            }
                          >
                            <FiEdit />
                          </button></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : this.state.searchType === "Sample" ? (
              <div className="tableWrapper" id="tablePrint">
                <Table
                  striped
                  bordered
                  hover
                  variant="dark"
                  className="rounded"
                  id="table-to-xls"
                >
                  <thead>
                    <tr>
                      <th width="50px" >
                        <Tooltipped>Job Number</Tooltipped>
                      </th>
                      <th width="60px" >
                        <Tooltipped>Start Date</Tooltipped>
                      </th>
                      <th width="60px" >
                        <Tooltipped>Finish Date</Tooltipped>
                      </th>
                      <th width="50px" style={styles.columnTitle, currentCheckbox === "style" ? thStyle : null}>
                        <Tooltipped>Style</Tooltipped>
                      </th>

                      <th width="100px" style={styles.columnTitle, currentCheckbox === "stone" ? thStyle : null}>
                        <Tooltipped>Stone</Tooltipped>
                      </th>
                      <th width="60px" >
                        <Tooltipped>Stone Price</Tooltipped>
                      </th>
                      <th
                        width="200px"

                        style={currentCheckbox === "description" ? thStyle : null}
                      >
                        <Tooltipped>Description</Tooltipped>
                      </th>
                      <th width="70px" >Band</th>
                      {/* <th>Stone Price</th> */}
                      <th width="100px" >
                        <Tooltipped>Current Location</Tooltipped>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.jobsData.map(item => {
                      return (
                        <tr>
                          {/* <td>{item.finishedCode}</td>  */}
                          <td>
                            <Link
                              style={{ color: "#bba3d0" }}
                              to={{
                                pathname: "/jobInfo",
                                state: {
                                  jobId: item.job_number
                                }
                              }}
                            >
                              {item.job_number}
                            </Link>
                          </td>
                          <td>{format(item.date_started, 'MM-DD-YYYY')}</td>
                          <td>{format(item.finish_date, 'MM-DD-YYYY')}</td>
                          <td>{item.style === "z" ? " " : item.style}</td>
                          <td>{item.stone === "z" ? " " : item.stone}</td>
                          <td>{item.stone_price}</td>
                          <td>{item.description === "z" ? " " : item.description}</td>
                          <td>{item.band}</td>
                          {/* <td>$146.55</td> */}
                          <td>
                            {item.location ? item.location.location_title : null}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : this.state.searchType === "Shipped" ? (
              <div className="tableWrapper" id="tablePrint">
                <Table
                  striped
                  bordered
                  hover
                  variant="dark"
                  className="rounded"
                  id="table-to-xls"
                >
                  <thead>
                    <tr>
                      <th width="100px" >
                        <Tooltipped>Finished Code</Tooltipped>
                      </th>
                      <th width="50px">
                        <Tooltipped>Job Number</Tooltipped>
                      </th>
                      <th width="60px">
                        <Tooltipped>Type</Tooltipped>
                      </th>
                      <th width="200px">
                        <Tooltipped>Notes</Tooltipped>
                      </th>
                      <th width="100px">
                        <Tooltipped>Description</Tooltipped>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.jobsData.map(item => {
                      return (
                        <tr>
                          <td>{item.finished_code}</td>
                          <td>
                            <Link
                              style={{ color: "#bba3d0" }}
                              to={{
                                pathname: "/jobInfo",
                                state: {
                                  jobId: item.job_number
                                }
                              }}
                            >
                              {item.job_number}
                            </Link>
                          </td>
                          <td>
                            <td>{this.returnJobType(item.jobTypeId)}</td>
                          </td>
                          <td>
                            <Tooltipped>{item.notes}</Tooltipped>
                          </td>
                          <td>
                            {item.description !== "z" ? item.description : " "}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
                    <div className="tableWrapper" id="tablePrint">
                      {console.log(this.state.jobsData)}
                      <DataTable data={this.state.jobsData} history={this.props.history}/>
                      {/* <Table
                        striped
                        bordered
                        hover
                        variant="dark"
                        className="rounded"
                        id="table-to-xls"
                      >
                        <thead>
                          <tr>
                            <th width="50px">
                              <Tooltipped>Job Number</Tooltipped>
                            </th>
                            <th width="60px">
                              <Tooltipped>Date Started</Tooltipped>
                            </th>
                            <th width="60px">
                              <Tooltipped>Finish Date</Tooltipped>
                            </th>
                            <th width="60px">
                              <Tooltipped>Type</Tooltipped>
                            </th>
                            <th width="200px">
                              <Tooltipped>Notes</Tooltipped>
                            </th>
                            <th width="100px">
                              <Tooltipped>Current Location</Tooltipped>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.jobsData.map(item => {
                            return (
                              <tr>
                                <td>
                                  <Link
                                    style={{ color: "#bba3d0" }}
                                    to={{
                                      pathname: "/jobInfo",
                                      state: {
                                        jobId: item.job_number
                                      }
                                    }}
                                  >
                                    {item.job_number}
                                  </Link>
                                </td>
                                <td>{format(item.date_started, 'MM-DD-YYYY')}</td>
                                <td>{format(item.finish_date, 'MM-DD-YYYY')}</td>
                                <td>{this.returnJobType(item.jobTypeId)}</td>
                                <td>
                                  <Tooltipped>{item.notes}</Tooltipped>
                                </td>
                                <td>
                                  <Tooltipped>
                                    {item.location ? item.location.location_title : null}
                                  </Tooltipped>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table> */}
                    </div>
                  )}

            <div className="printDiv">
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="table-to-xls"
                filename="JobList"
                sheet="tablexls"
                buttonText="Download Excel Sheet"
              />
            </div>


          </div> : 
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Spinner animation="grow" variant="secondary" />
            <Spinner animation="grow" variant="secondary" style={{background: '#bba3d0', marginRight: '10px' ,  marginLeft: '10px'}} />
            <Spinner animation="grow" variant="secondary" />
            <Spinner animation="grow" variant="secondary" style={{background: '#bba3d0', marginRight: '10px',  marginLeft: '10px'}} />
            {/* <Spinner animation="grow" variant="secondary" /> */}

          </div>}


        <AddFinishedCodeModal
          show={this.state.modalShow}
          onHide={this.modalClose}
          code={this.state.finishedCode === null ? "" : this.state.finishedCode}
          handleInput={this.handleUpdateFinishInput}
          jobId={this.state.jobId}
          renderFinished={this.grabAllFinishedJob}
        />
      </React.Fragment>
    );
  }
}

export default listJobs;
