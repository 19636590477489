import React from "react";
import NavButtons from "../../navBtns";
import { FormControl, Form, Modal } from "react-bootstrap";
import MyVerticallyCenteredModal from "../../modals/successPartModal";
import "./style.css";
import axios from "axios";
import { endpoint } from "../../../utils/endpoint";

const styles = {
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  errorMsg: {
    margin: "25px 0",
    paddingBottom: 10
  },
  confirm: {
    borderRadius: "0.25rem",
    width: 140,
    color: "white",
    backgroundColor: "rgb(160, 110, 204)",
    fontWeight: "bold",
    border: "#454d55 solid 1px",
    padding: "0.6%"
  }
};

class createLocations extends React.Component {
  state = {
    navBtnColor: "",
    modalShow: false,
    partNumber: "",
    description: null,
    weight: null,
    metalCost: null,
    castingCost: null,
    internalCost: null,
    wholeSalePrice: null,
    notes: null,
    quantity: 0,
    partLocationsTableId: "",
    imgLink: "www.",
    suggestions: [],
    partLocations: [],
    locationId: "None",
    partId: "",
    metaltype: "",
    errorModalOpen: false
  };

  componentDidMount() {
    this.setState({ navBtnColor: "#454d55" });
    this.grabPartLocations();
  }

  handleInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggleErrorModal = () => {
    this.setState({ errorModalOpen: !this.state.errorModalOpen });
  };
  grabPartLocations = async () => {
    let res = await axios.get(endpoint + `/grabAllLocations/2`, {
      headers: {
        "Access-Control-Allow-Origin": "*"
      }
    });
    let data = res.data;
    this.setState({ currentJob: data });
    this.setState({ partLocations: data });
  };

  onTextChange = e => {
    const value = e.target.value;
    this.getSuggestions(value);
  };

  getSuggestions = async partName => {
    if (partName.length > 0) {
      let res = await axios.get(
        endpoint + `/searchForPartLocation/${partName}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*"
          }
        }
      );
      let data = res.data;
      this.setState({ suggestions: data });
    } else {
      this.setState({ suggestions: [] });
    }
  };

  renderSuggestions() {
    const { suggestions } = this.state;
    // CompDidMount due to async will start off as an obj until it is filtered to an arr of possible
    // titles in the this.onTextChange function
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <div className="suggestions-ul">
        {/* TODO: Choose if this should render the partLocTable or the jobLoc table */}
        {suggestions.map(item => (
          <div
            className="suggestListI"
            onClick={() => this.selectionLocation(item.id, item.locationTitle)}
          >
            {item.locationTitle}
          </div>
        ))}
      </div>
    );
  }

  handleState = e => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleJobType = event => {
    let jobType = event.target.value;
    this.setState({
      jobType
    });
  };

  renderSuggestions() {
    const { suggestions } = this.state;
    // CompDidMount due to async will start off as an obj until it is filtered to an arr of possible
    // titles in the this.onTextChange function
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <div className="suggestions-ul">
        {/* TODO: Choose if this should render the partLocTable or the jobLoc table */}
        {suggestions.map(item => (
          <div
            className="suggestListI"
            onClick={() =>
              this.selectionLocation(item.id, item.partLocationTitle)
            }
          >
            {item.partLocationTitle}
          </div>
        ))}
      </div>
    );
  }

  selectionLocation = (id, locationName) => {
    var partLocInput = document.getElementById("partLocationID");
    partLocInput.value = locationName;
    this.setState({
      partLocationsTableId: id
    });
    this.setState({
      suggestions: []
    });

  };



  createNewPart = e => {
    e.preventDefault();

    axios
      .post(endpoint + "/createMaterial", {
        partNumber: this.state.partNumber,
        description: this.state.description,
        weight: this.state.weight,
        metalCost: this.state.metalCost,
        castingCost: this.state.castingCost,
        internalCost: this.state.internalCost,
        wholeSaleCost: this.state.wholeSalePrice,
        notes: this.state.notes,
        quantity: this.state.quantity,
        locationId: this.state.locationId,
        // imgLink: this.state.imgLink,
        dateAdded: 12.12,
        metaltype: this.state.metaltype
      })
      .then(response => {
  

        this.setState({
          partId: response.data.id,
          description: "",
          weight: "",
          metalCost: "",
          castingCost: "",
          internalCost: "",
          wholeSalePrice: "",
          notes: "",
          quantity: "",
          locationId: "None",
          metaltype: ""
        });

        this.setState({ modalShow: true });
      })
      .catch(error => {
        console.log(error);
        this.setState({ errorModalOpen: true });
      });
  };

  modalClose = () => {
    this.setState({ modalShow: false });
    this.props.history.push("/Parts");
  };
  render() {
    return (
      <React.Fragment>
        <NavButtons togglePartsBtn={this.state.navBtnColor} />
        <form onSubmit={this.createNewPart}>
          <div className="containerDiv">
            <div className="inputDivOne">
              <div className="dateInputDiv">
                <label className="jobsLabel">Part #</label>
                <FormControl
                  className="dateInputBox jobsInput"
                  type="text"
                  name="partNumber"
                  autoComplete="off"
                  value={this.state.partNumber}
                  onChange={this.handleState}
                />
              </div>
              <div className="dateInputDiv">
                <label className="jobsLabel">Description</label>
                <FormControl
                  className="dateInputBox jobsInput"
                  type="text"
                  name="description"
                  autoComplete="off"
                  value={this.state.description}
                  onChange={this.handleState}
                />
              </div>
            </div>

            <div className="inputDivOne">
              <div className="jobInputDiv">
                <label className="jobsLabel">Weight</label>
                <FormControl
                  className="jobsInput"
                  type="text"
                  placeholder=""
                  name="weight"
                  autoComplete="off"
                  onChange={this.handleState}
                  value={this.state.weight}
                />
              </div>

              <div className="jobInputDiv">
                <label className="jobsLabel">Metal Cost</label>
                <FormControl
                  className="jobsInput"
                  type="text"
                  placeholder="$"
                  name="metalCost"
                  autoComplete="off"
                  onChange={this.handleState}
                  value={this.state.metalCost}
                />
              </div>
            </div>

            <div className="inputDivOne">
              <div className="jobInputDiv">
                <label className="jobsLabel">Casting Labor Cost</label>
                <FormControl
                  className="jobsInput"
                  type="text"
                  placeholder="$"
                  name="castingCost"
                  autoComplete="off"
                  value={this.state.castingCost}
                  onChange={this.handleState}
                />
              </div>

              <div className="jobInputDiv">
                <label className="jobsLabel">Internal Labor Cost</label>
                <FormControl
                  className="jobsInput"
                  type="text"
                  placeholder="$"
                  name="internalCost"
                  autoComplete="off"
                  value={this.state.internalCost}
                  onChange={this.handleState}
                />
              </div>
            </div>

            <div className="inputDivOne">
              <div className="jobInputDiv">
                <label className="jobsLabel">Wholesale Price</label>
                <FormControl
                  className="jobsInput"
                  type="text"
                  placeholder="$"
                  name="wholeSalePrice"
                  autoComplete="off"
                  value={this.state.wholeSalePrice}
                  onChange={this.handleState}
                />
              </div>

              <div className="jobInputDiv">
                <label className="jobsLabel">Notes</label>
                <FormControl
                  className="jobsInput"
                  type="text"
                  placeholder=""
                  name="notes"
                  autoComplete="off"
                  value={this.state.notes}
                  onChange={this.handleState}
                />
              </div>
            </div>

            <div className="inputDivOne">
              <div
                className="jobInputDiv suggestParent"
                style={{ border: "none" }}
              >
                <label className="jobsLabel">Part Location</label>
                <Form.Control
                  as="select"
                  className="jobsInput"
                  defaultValue="Job"
                  name="locationId"
                  autoComplete="off"
                  onChange={this.handleInput}
                  value={this.state.locationId}
                >
                  <option value={null}>{"None"}</option>
                  {this.state.partLocations.map(item => {
                    return (
                      <option value={item.id}>{item.location_title}</option>
                    );
                  })}
                </Form.Control>
              </div>

              <div className="jobInputDiv">
                <label className="jobsLabel">Quantity</label>
                <FormControl
                  className="jobsInput"
                  type="number"
                  value={this.state.quantity}
                  placeholder=""
                  name="quantity"
                  disabled={
                    this.state.locationId === "None" ||
                    this.state.locationId === ""
                  }
                  onChange={this.handleState}
                />
                <label className="jobsLabel">Metal Type</label>
                <FormControl
                  className="jobsInput"
                  type="text"
                  value={this.state.metaltype}
                  placeholder=""
                  name="metaltype"
                  autoComplete="off"
                  onChange={this.handleState}
                />
              </div>
            </div>
          </div>
          <div
            id="buttonDiv"
            style={{
              display: "flex",
              width: "23%",
              margin: "45px auto",
              justifyContent: "flex-end"
            }}
          >
            <button
              variant="outline-secondary"
              id="createJobButton"
              type="submit"
            >
              Create
            </button>
          </div>
        </form>

        <MyVerticallyCenteredModal
          show={this.state.modalShow}
          onHide={this.modalClose}
          partnumberforqr={this.state.partNumber}
        />

        <Modal
          show={this.state.errorModalOpen}
          onHide={this.toggleErrorModal}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Error!</Modal.Title>
          </Modal.Header>
          <Modal.Body
          >
            <div style={styles.modalContainer}>
              <h4 style={styles.errorMsg}>
                "<strong>{this.state.partNumber}</strong>" already exists.
                Please try again.{" "}
              </h4>
              <button
                id="addExtraBttn"
                style={styles.confirm}
                onClick={this.toggleErrorModal}
              >
                Confirm
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default createLocations;
