import React from "react";
import axios from "axios";
import NavButtons from "../../navBtns";
import FormControl from "react-bootstrap/FormControl";
import JobLocationsTable from "../../jobsComponents/jobLocationsTable";
import InputGroup from "react-bootstrap/InputGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import PartsTable from "../../partsComponents/partsLocation";
import format from "date-fns/format";
import { endpoint } from "../../../utils/endpoint";
import LocationDataTable from '../../LocationsDataTable';
import "./style.css";

const inputTitleStyles = {
  textAlign: "center",
  marginBottom: 5
};

class SearchLocations extends React.Component {
  state = {
    // My JSON array of objects...all part locations for now
    suggestions: [],
    text: "",
    locationBtnColor: " ",
    searchType: "",
    modalShow: false,
    selectedLocation: [],
    addingJobNowToggle: false,
    addingPartNowToggle: false,
    jobOrderId: "",
    allOfOneLocation: [],
    showQTYInput: false,
    partNumber: "",
    partAddQTY: 0,
    locationTitle: "",
    errMessage: "",
    addBtnColor: "#454d55",
    addBtnText: "Add Job"
  };

  addJobButtonClicked = async () => {
    const cloneState = this.state;
      if (!cloneState.addingJobNowToggle) {
        await this.searchInput.focus();
      }
      this.setState({
        addingJobNowToggle: !cloneState.addingJobNowToggle
      });
  };

  addingPartNow = async () => {
    this.setState({
      addingPartNowToggle: !this.state.addingPartNowToggle,
      addingJobNowToggle: false
    });
    await this.searchInput.focus();
  };

  addJobInputChange = e => {
    let value = e.target.value;
    this.setState({
      jobOrderId: value
    });
  };

  enterPressWatcher = event => {
    const keyPressed = event.code;
    const cloneState = this.state;
    if (keyPressed === "Enter") {
      if (cloneState.addingJobNowToggle) {
        // let scannedJob = document.getElementById("mainSearch").value
        this.addJobToLocation(this.searchInput.value);
        //await this.refreshLocation(cloneState.selectedLocationId);
        this.searchInput.focus();
      } else if (cloneState.addingPartNowToggle) {
        this.addPartToLocation(this.searchInput.value);
        this.locationScanned(cloneState.selectedLocationId);
      } else if (!cloneState.showQTYInput) {
        if (cloneState.locationType) {
          this.setState({
            suggestions: []
          })
          this.locationScanned(this.searchInput.value);
        } else {
          this.setState({
            suggestions: []
          })
          let input = document.querySelector("input[type='search']");
          let inputVal = input.value;
          this.locationScanned(inputVal);
        }
      }
    }
  };

  addJobToLocation = async jNum => {
    axios
      .put(endpoint + "/updateJobOrderLocation", {
        dateEntered: format(new Date(), "YYYY-MM-DD"),
        newLocationId: this.state.selectedLocationId,
        jobNumber: jNum
      })
      .then(data => {

        this.setState({
          jobOrderId: "",
          selectedLocation: [data.data, ...this.state.selectedLocation],
          searchTerm: ""
        });
        this.setState({ suggestions: [] });
      });
  };

  addPartToLocation = partNumber => {
    axios
      .put(endpoint + "/updatePartLocationWithNewPart", {
        newLocationId: this.state.selectedLocationId,
        partNumber: partNumber
        // jobNumber: jobNum
      })
      .then(data => {
        console.log("Updated part loc: ", data);
        // this.setState({
        //   jobOrderId: ""
        // })
      });
  };
  componentDidMount() {
    this.grabAllLocations()
    if (this.props.location.state) {
      this.locationScanned(this.props.location.state.locationId);
    }
    console.log(this.searchInput);
    let input = document.querySelector("input[type='search']");
    input.focus();
    //this.searchInput.focus();
    this.setState({ locationBtnColor: "#454d55" });
    window.addEventListener("keydown", this.enterPressWatcher, false);
  }
  componentWillUnmount() {
    window.removeEventListener("keydown", this.enterPressWatcher, false);
  }

  modalClose = () => this.setState({ modalShow: false });

  handleTypeSearch = async searchType => {
    await this.setState({
      searchType,
      locationTitle: ""
    });

    this.renderAllJobOrPartLocations();
  };

  deleteLocationCheck = () => {
    this.setState({ modalShow: true });
  };

  displayError = errMessage => {
    this.setState({ errMessage });
  };

  getSuggestions = async term => {
    if (term.length > 0) {
      let arrayOfTitlesAndIds = [];
      let res;

      if (this.state.addingJobNowToggle) {
        res = await axios.get(endpoint + `/searchAllJobsList/${term}`);
      } else if (this.state.addingPartNowToggle) {
        res = await axios.get(endpoint + `/searchAllPartsByNumber/${term}`);
      } else {
        res = await axios.get(endpoint + `/searchLocations/${term}`);
      }

      let data = res.data;

      if (data.length === 0) {
        return this.displayError(`Nothing found for "${term}"`);
      }
      if (this.state.addingJobNowToggle || this.state.addingPartNowToggle) {
        this.setState({ suggestions: data, errMessage: "" });
      } else {
        await data.map(item => {
          arrayOfTitlesAndIds.push([
            item.id,
            item.location_title,
            item.locationTypeId
          ]);
        });
        await this.setState({
          suggestions: arrayOfTitlesAndIds,
          errMessage: ""
        });
      }
      console.log("WHERE?", this.state.suggestions);
    } else {
      this.setState({ suggestions: [], errMessage: "" });
    }
  };

  onTextChange = e => {
    // console.log("here", this.searchInput.value);
    const value = e.target.value;
    this.setState({
      searchTerm: value
    });

    this.getSuggestions(value);
  };

  partOrJobSuggestionSelected = () => { };

  suggestionsSelected = async (
    selectedLocationId,
    locationTitle,
    locationType
  ) => {
    console.log(selectedLocationId, "HEHEH", locationTitle, locationType)
    if (this.state.addingJobNowToggle) {
      let jobNum = locationTitle;
      this.addJobToLocation(jobNum);
      this.setState({ suggestions: [] });
      await this.searchInput.focus();
      // this.locationScanned(this.state.selectedLocationId);
    } else if (this.state.addingPartNowToggle) {
      let partNum = locationTitle;
      this.addPartToLocation(partNum);
      this.setState({ suggestions: [] });
      this.locationScanned(this.state.selectedLocationId);
    } else {
      this.setState({
        locationType,
        searchTerm: "",
        locationTitle,
        selectedLocationId,
        allOfOneLocation: [],
        // selectedLocation: []
      });
      axios
        .get(
          this.state.locationType === 1
            ? endpoint + `/grabAllFromThisJobLocation/${selectedLocationId}`
            : endpoint + `/grabAllFromThisPartLocation/${selectedLocationId}`
        )
        .then(response => {
          let data = response.data;
          console.log("Data going into state TRAE:", data);
          this.setState({ selectedLocation: data, suggestions: [] });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  renderSuggestions() {
    const { suggestions } = this.state;
    // CompDidMount due to async will start off as an obj until it is filtered to an arr of possible
    // titles in the this.onTextChange function
    if (suggestions.length !== 0) {

      return (
        <div className="suggestions-ul">
          {/* TODO: Choose if this should render the partLocTable or the jobLoc table */}
          {suggestions.map(item => (
            <div
              className="suggestListI"
              onClick={() =>
                this.suggestionsSelected(
                  item[0],
                  this.state.addingJobNowToggle
                    ? item.job_number
                    : this.state.addingPartNowToggle
                      ? item.partnumber
                      : item[1],
                  item[2]
                )
              }
            >
              {this.state.addingJobNowToggle
                ? item.job_number
                : this.state.addingPartNowToggle
                  ? item.partnumber
                  : item[1]}
            </div>
          ))}
        </div>
      );
    }
  }

  grabAllLocations() {
    axios
      .get(endpoint + `/grabAllLocs`)
      .then(response => {
        let data = response.data;
        this.setState({
          allOfOneLocation: data
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // refreshLocation = async (locationId) => {
  //   console.log(locationId)
  //   axios
  //   .get(endpoint + `/grabAllFromThisJobLocation/${locationId}`)
  //   .then(async response => {
  //     let data = response.data;
  //     console.log('data',response.data)
  //     this.setState({
  //       selectedLocation: data
  //     })
  //   })
  // }

  locationScanned = async id => {
    axios
      .get(endpoint + `/locationScanned/${id}`)
      .then(async response => {
        let data = response.data;
        if (this.state.addingJobNowToggle) {
          this.setState({
            addingJobNowToggle: false
          });
          await this.suggestionsSelected(
            data[0].id,
            data[0].location_title,
            data[0].locationTypeId
          );
          this.setState({
            addingJobNowToggle: true
          });
        } else if (this.state.addingPartNowToggle) {
          this.setState({
            addingPartNowToggle: false
          });
          await this.suggestionsSelected(
            data[0].id,
            data[0].location_title,
            data[0].locationTypeId
          );
          this.setState({
            addingPartNowToggle: true
          });
        } else {
          await this.suggestionsSelected(
            data[0].id,
            data[0].location_title,
            data[0].locationTypeId
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  renderAllJobOrPartLocations() {
    this.setState({
      selectedLocation: [],
      locationType: 0
    });
    if (this.state.searchType !== "" && this.state.searchType !== "All") {
      // if (this.state.searchType ==="Job"){
      let type = this.state.searchType === "Job" ? 1 : 2;
      axios
        .get(endpoint + `/grabAllLocations/${type}`)
        .then(response => {
          let data = response.data;
          this.setState({
            allOfOneLocation: data
          });

          // this.setState({ suggestions:  });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (this.state.searchType === "All") {
      this.grabAllLocations();
    } else {
      return null;
    }
  }

  handleAddQTYInputShow = async (partNumber, partId) => {
    await this.setState({
      showQTYInput: !this.state.showQTYInput,
      partNumber,
      partId
    });
    if (this.state.showQTYInput) {
      this.addPartQTYInput.focus();
    }
  };

  handleAddQTY = partNumber => {
    axios
      .post(endpoint + "/updateMaterialQuantity", {
        id: this.state.partId,
        quantity: this.state.partAddQTY
      })
      .then(data => {
        this.setState({
          showQTYInput: !this.state.showQTYInput,
          partAddQTY: 0
        });
        this.locationScanned(this.state.selectedLocationId);
      })
      .catch(err => {
        console.log(err);
      });
  };

  handlePartQTYInput = e => {
    this.setState({
      partAddQTY: e.target.value
    });
  };

  editLocation = e => {
    this.props.history.push({
      pathname: "/editLocation",
      state: {
        locationTitle: this.state.locationTitle,
        locationType: this.state.locationType,
        locationId: this.state.selectedLocationId
      }
    });
  };

  render() {
    const {
      text,
      errMessage,
      searchTerm,
      addingJobNowToggle,
      addingPartNowToggle
    } = this.state;
    return (
      <div>

        <NavButtons toggleLocateBtn={this.state.locationBtnColor} />
        {!this.state.locationType && <LocationDataTable locationInfo={this.state.allOfOneLocation} locationClicked={this.locationScanned} />}

        <div id="searchLocationInputForm">
          {this.state.locationTitle.length ? (
            <div id="locationTitleDiv">
              <h6>
                <b>{this.state.locationTitle}</b>
              </h6>

              <button
                type="submit"
                id="editLocationBtn"
                onClick={this.editLocation}
              >
                Edit Location
              </button>
            </div>
          ) : (
              <div style={{ width: "10%" }}></div>
            )}

          {this.state.showQTYInput === false && this.state.locationType ? (
            <div className="suggestParent">
              {/* ternary operator to display search input label  */}
              {addingJobNowToggle ? (
                <p style={inputTitleStyles}>
                  Enter the <strong>Job #</strong> to be added:
                </p>
              ) : addingPartNowToggle ? (
                <p style={inputTitleStyles}>
                  Enter the <strong>Part #</strong> to be added:
                </p>
              ) : (
                    <p style={inputTitleStyles}>Search Location Name:</p>
                  )}

              <InputGroup>
                <DropdownButton
                  as={InputGroup.Prepend}
                  variant="outline-secondary"
                  title='All locations'
                  id="input-group-dropdown-1"
                  onClick={() => this.handleTypeSearch("All")}
                >
                  {/* <Dropdown.Item
                    eventKey="abc"
                    
                  >
                    All Locations
                  </Dropdown.Item> */}
                  {/* <Dropdown.Divider />
                  <Dropdown.Item
                    eventKey="ahc"
                    onClick={() => this.handleTypeSearch("Job")}
                  >
                    Job Locations
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    eventKey="a"
                    onClick={() => this.handleTypeSearch("Part")}
                  >
                    Part Locations
                  </Dropdown.Item> */}
                </DropdownButton>
                <FormControl
                  ref={input => {
                    this.searchInput = input;
                  }}
                  autoComplete="off"
                  aria-describedby="basic-addon1"
                  placeholder= 'Search location'
                  value={this.state.searchTerm}
                  onChange={this.onTextChange}
                />
              </InputGroup>
              {this.renderSuggestions()}
              
              {/* Display an error due to no search results/suggestions  */}
              {errMessage && searchTerm ? (
                <ErrorMessage error={errMessage} />
              ) : null}
            </div>
          ) : null}

          {this.state.showQTYInput ? (
            <>
              <h5 id="flash">
                Please finish adding to part number: {this.state.partNumber}{" "}
              </h5>
              <div id="partQTYDiv">
                <input
                  onChange={this.handlePartQTYInput}
                  placeholder="QTY"
                  ref={input => {
                    this.addPartQTYInput = input;
                  }}
                />
                <button onClick={this.handleAddQTY}>+</button>
              </div>
            </>
          ) : (
              <div style={{ width: "10%" }}></div>
            )}
          {this.state.locationType === 1 && 
          <button style={{
                      color: "#bba3d0",
                      backgroundColor: this.state.addingJobNowToggle ? "#454d55" : "transparent",
                      borderColor: " #6e6e6e",
                      borderRadius: ".25rem",
                      fontWeight: "bolder",
                      float: "right"
                    }}
                    onClick={this.addJobButtonClicked}>
                      {this.state.addingJobNowToggle ? "Done Adding" : "Add Job"}
          </button>}
        </div>

        {this.state.selectedLocation.length >= 0 &&
          this.state.locationType === 1 ? (
            <JobLocationsTable
              //toggleAddingJob={this.addingJobNow}
              locationData={this.state.selectedLocation}
              history={this.props.history}
            //locationTitle={this.state.locationTitle}
            //locationType={1}
            //locationId={this.state.selectedLocationId}
            //addingJobBttnColor={this.state.addingJobNowToggle}
            />
          ) : this.state.selectedLocation.length >= 0 &&
            this.state.locationType === 2 ? (
              // else it must be a part location, so send the prop to that table.
              <PartsTable
                handleAddQTY={this.handleAddQTYInputShow}
                showQTYInput={this.state.showQTYInput}
                toggleAddingPart={this.addingPartNow}
                location={this.state.selectedLocation}
                locationTitle={this.state.locationTitle}
                locationType={2}
                locationId={this.state.selectedLocationId}
                locationId={this.state.selectedLocationId}
              />
            ) : null}

        {/* <div id="searchLocationInputForm">
          {this.state.locationTitle.length ? (
            <div id="locationTitleDiv">
              <h6>
                <b>{this.state.locationTitle}</b>
              </h6>

              <button
                type="submit"
                id="editLocationBtn"
                onClick={this.editLocation}
              >
                Edit Location
              </button>
            </div>
          ) : (
              <div style={{ width: "10%" }}></div>
            )} 

          {this.state.showQTYInput === false ? (
            <div className="suggestParent">
              {/* ternary operator to display search input label 
              {addingJobNowToggle ? (
                <p style={inputTitleStyles}>
                  Enter the <strong>Job #</strong> to be added:
                </p>
              ) : addingPartNowToggle ? (
                <p style={inputTitleStyles}>
                  Enter the <strong>Part #</strong> to be added:
                </p>
              ) : (
                    <p style={inputTitleStyles}>Search Location Name:</p>
                  )}

              <InputGroup>
                <DropdownButton
                  as={InputGroup.Prepend}
                  variant="outline-secondary"
                  title={this.state.searchType}
                  id="input-group-dropdown-1"
                >
                  <Dropdown.Item
                    eventKey="abc"
                    onClick={() => this.handleTypeSearch("All")}
                  >
                    All Locations
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    eventKey="ahc"
                    onClick={() => this.handleTypeSearch("Job")}
                  >
                    Job Locations
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    eventKey="a"
                    onClick={() => this.handleTypeSearch("Part")}
                  >
                    Part Locations
                  </Dropdown.Item>
                </DropdownButton>
                <FormControl
                  ref={input => {
                    this.searchInput = input;
                  }}
                  autoComplete="off"
                  aria-describedby="basic-addon1"
                  placeholder=""
                  value={this.state.searchTerm}
                  onChange={this.onTextChange}
                />
              </InputGroup>
              {this.renderSuggestions()}
              {/* Display an error due to no search results/suggestions 
              {errMessage && searchTerm ? (
                <ErrorMessage error={errMessage} />
              ) : null}
            </div>
          ) : null}

          {this.state.showQTYInput ? (
            <>
              <h5 id="flash">
                Please finish adding to part number: {this.state.partNumber}{" "}
              </h5>
              <div id="partQTYDiv">
                <input
                  onChange={this.handlePartQTYInput}
                  placeholder="QTY"
                  ref={input => {
                    this.addPartQTYInput = input;
                  }}
                />
                <button onClick={this.handleAddQTY}>+</button>
              </div>
            </>
          ) : (
              <div style={{ width: "10%" }}></div>
            )}

        </div>

        {this.state.allOfOneLocation.length ? (
          <AllLocationsTable
            locationClicked={this.locationScanned}
            locationInfo={this.state.allOfOneLocation}
          />
        ) : null}

        <MyVerticallyCenteredModal
          show={this.state.modalShow}
          onHide={this.modalClose}
        />
        {
          this.state.selectedLocation.length >= 0 &&
            this.state.locationType === 1 ? (
              <JobLocationsTable
                toggleAddingJob={this.addingJobNow}
                location={this.state.selectedLocation}
                locationTitle={this.state.locationTitle}
                locationType={1}
                locationId={this.state.selectedLocationId}
                addingJobBttnColor={this.state.addingJobNowToggle}
              />
            ) : this.state.selectedLocation.length >= 0 &&
              this.state.locationType === 2 ? (
                // else it must be a part location, so send the prop to that table.
                <PartsTable
                  handleAddQTY={this.handleAddQTYInputShow}
                  showQTYInput={this.state.showQTYInput}
                  toggleAddingPart={this.addingPartNow}
                  location={this.state.selectedLocation}
                  locationTitle={this.state.locationTitle}
                  locationType={2}
                  locationId={this.state.selectedLocationId}
                  locationId={this.state.selectedLocationId}
                />
              ) : null} */}
      </div>
    );
  }
}

const ErrorMessage = props => {
  return (
    <em>
      <p style={{ color: "red", textAlign: "center" }}>{props.error}</p>
    </em>
  );
};

export default SearchLocations;
