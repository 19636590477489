import React from "react";
import FormControl from "react-bootstrap/FormControl";
import "./style.css";

const SampleInputs = props => {
  const { style, description, stone, stonePrice, band} = props.sampleState;
  return (
    <div id="wrapper">
      <br />
      <hr />

      <div className="inputDivOne">
        <div className="jobInputDiv">
          <label className="locationLabel">Style</label>
          <FormControl
            autoComplete="off"
            type="text"
            name="style"
            placeholder=""
            onChange={props.handleChangeProp}
            value={style}
          />
        </div>

        <div className="jobInputDiv">
          <label className="locationLabel">Description</label>
          <FormControl
            autoComplete="off"
            type="text"
            placeholder=""
            name="description"
            onChange={props.handleChangeProp}
            value={description}
          />
        </div>
      </div>

      <div className="inputDivOne">
        <div className="jobInputDiv">
          <label className="locationLabel">Stone</label>
          <FormControl
            type="text"
            placeholder=""
            name="stone"
            autoComplete="off"
            onChange={props.handleChangeProp}
            value={stone}
          />
        </div>

        <div className="jobInputDiv">
          <label className="locationLabel">Stone Price</label>
          <FormControl
            type="text"
            placeholder=""
            name="stonePrice"
            autoComplete="off"
            onChange={props.handleChangeProp}
            value={stonePrice}
          />
        </div>
      </div>

      <div className="inputDivOne">
        <div className="jobInputDiv">
          <label className="locationLabel">Band</label>
          <FormControl
            type="text"
            placeholder=""
            name="band"
            autoComplete="off"
            onChange={props.handleChangeProp}
            value={band}
          />
        </div>
      </div>
    </div>
  );
};

export default SampleInputs;
