import React from "react";

export default function PrintTable(props) {
  return (
    <div
      style={{
        position: "absolute",
        transform: "translateX(-500%)"
      }}
    >
      <table style={{ width: "400px" }} id="print-table">
        <thead>
          <tr>
            <th>Part #</th>
            <th>Description</th>
            <th>Quantity</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {props.parts.map((part, index) => (
            <tr key={index}>
              <td>{part.partNum}</td>
              <td>{part.desc}</td>
              <td>{part.qty}</td>
              <td>${parseFloat(part.price).toFixed(2)}</td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td></td>
            <td>Total:</td>
            <td>${props.totalParts(props.parts)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
