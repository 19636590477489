import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useEffect } from "react";
import { endpoint } from "../../../utils/endpoint";
// import Table from "react-bootstrap/Table";

const UpdateModal = props => {
  const updateCode = () => {
    console.log(props.code);
    console.log(props.jobId);
    axios
      .put(endpoint + "/updateFinishedJobCode", {
        jobOrderId: props.jobId,
        finishedCode: props.code
      })
      .then(res => {
        console.log(res);
        props.onHide();
        props.renderFinished();
      });
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* {props.jobnum} */}
        <div style={{ borderRadius: ".25rem" }}>
          <input
            style={{
              width: "75%",
              // borderRadius: ".25rem",
              padding: ".5rem",
              border: " #454d55 solid .15rem"
            }}
            type="text"
            value={props.code}
            name="finishedCode"
            autoComplete="off"
            onChange={props.handleInput}
          />
          <button
            style={{
              width: "25%",
              color: "#bba3d0",
              padding: ".65rem",
              background: "#454d55",
              border: "none",
              fontWeight: "bold"
            }}
            onClick={updateCode}
          >
            Update Code
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default UpdateModal;
