import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import QRCode from "qrcode.react";
import print from 'print-js';
import { Link } from 'react-router-dom';



class RePrintModal extends React.Component {

  printBarCode = () => {
    print('QRDiv', 'html');
    this.props.onHide()
  }

  render() {

    return (
      <div>
        <Modal
          {...this.props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div style={{ marginTop: "5px" }}></div>
          <div id="QRDiv" style={{ display: "flex", justifyContent: "center" }}>
            {this.props.location ? <QRCode value={this.props.location.toString()} /> : <QRCode value={this.props.jobId.toString()} />}
          </div>
          <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={this.printBarCode} style={{ backgroundColor: "#bba3d0", border: "none" }}>Print Barcode</Button>
            {this.props.location ? <Link to={{
              pathname: "/searchLocation",
              state: {
                locationId: this.props.location
              }
            }}><Button style={{ backgroundColor: "#454d55", border: "none" }}>Go to Location</Button></Link> :
              <Link to={{
                pathname: "/jobInfo",
                state: {
                  jobId: this.props.jobId
                }
              }}><Button style={{ backgroundColor: "#454d55", border: "none" }}> {this.props.location ? "Go To Location" : "Got To Job"}</Button></Link>}
          </Modal.Footer>
        </Modal>



      </div>
    );
  }
}

export default RePrintModal;