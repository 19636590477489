import React from "react";
import "./style.css";
import axios from "axios";
import { endpoint } from "../../../utils/endpoint";
import NavButtons from "../../navBtns";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Alert from 'react-bootstrap/Alert';
import { Link } from "react-router-dom";
import DeleteModal from "../../modals/updateLocationModal";
import { GoTools } from "react-icons/go";
import { FaShare } from "react-icons/fa";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Table from "react-bootstrap/Table";
import MovePartModal from "../../modals/movePartModal";
import Tooltipped from "../../tooltip/Tooltipped";
import format from "date-fns/format";
import { Label } from "react-bootstrap";

class locationsTable extends React.Component {
  state = {
    partsData: [],
    movePartModalShow: false,
    assemblePartModalShow: false,
    modalShow: false,
    updateModalShow: false,
    jobBtnColor: "",
    editButtons: false,
    suggestions: [],
    currentJob: [],
    currJobId: 0,
    jobNumber: 0,
    updatePart: {},
    updatedPartNum: 0,
    updatedDesc: "",
    updatedQuantity: 0,
    updatedPrice: 0,
    updatePartID: 0,
    navbarColor: "",
    newPartQuantity: 0,
    addingPartBool: false,
    firstPartAlreadyScanned: false,
    partQTY: 1,
    materialId: 0,
    partId: "",
    jobId: 0,
    partsArr: [],
    errMsg: "",
    putBackId: "",
    putBackPartNum: "",
    putBackQuant: "",
    putBackInitialQuant: "",
    modalId: "",
    modalQuant: "",
    modalPartNum: "",
    modalInitialQuant: "",
    partSearchErrMsg: "",
    allJobNumbers: [],
    allPartNumbers: []
  };

  componentDidMount() {
    window.addEventListener("keydown", this.jobInfoFunction, false);
    this.setState({ navbarColor: "#454d55" });
    this.searchInputTwo.focus();

    let jobId = this.props.location.state
      ? this.props.location.state.jobId
      : null;
    if (this.props.location.state !== undefined) {
      this.jobS(jobId);
    }

    axios
      .get(endpoint + `/grabAllJobOrdersForSearch`)
      .then(async response => {
        this.setState({
          allJobNumbers: response.data
        })
      })

    axios
      .get(endpoint + `/grabMaterialForSearch`)
      .then(async response => {
        this.setState({
          allPartNumbers: response.data
        })
      })
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.jobInfoFunction, false);
  }

  addPartOnclick = () => {
    this.searchInputTwo.value = null;
    this.setState({
      addingPartBool: !this.state.addingPartBool,
      firstPartAlreadyScanned: false,
      partId: "",
      partsArr: []
    });
    this.searchInputTwo.focus();
  };

  addPartToThisJob = number => {
    axios
      .post(endpoint + "/addPartToJob", {
        quantity: this.state.partQTY,
        partNumber: number,
        jobOrderId: this.state.currJobId,
        quantityChange: this.state.partQTY
      })
      .then(async response => {
        if (response.data !== "Part Added") {
          this.setState({
            modalShow: true,
            errMsg: response.data
          });
        }
        this.jobS(this.state.jobNumber);
        await this.setState({
          partQTY: 1,
          //suggestions: []
        });
        // TODO -> display error to user
      })
      .catch(err => {
        console.log(err);
      });
  };

  jobInfoFunction = async event => {
    if (event.key === "Enter") {
      if (this.state.addingPartBool) {
        // if I scan something and the array is NOT EMPTY, then add that number in the array to this job,clear the array and add the new
        // scan number to the array
        if (this.state.partsArr.length) {
          await this.addPartToThisJob(this.state.partsArr[0]);
          await this.setState({
            partsArr: [this.state.partId],
            // suggestions: []
          });
        } else {
          await this.setState({
            partsArr: [this.state.partId],
            //suggestions: []
          });
        }
        this.searchInputTwo.value = null;
      } else {
        this.jobS(this.state.jobId);
        this.searchInputTwo.value = null;
      }
    }
  };

  handleAddPartButton = () => {
    if (this.state.partsArr.length) {
      this.addPartToThisJob(this.state.partsArr[0]);
    } else {
      this.addPartToThisJob(this.searchInputTwo.value);
    }
    this.setState({
      partId: "",
      partsArr: []
    });
  };

  jobS = jNum => {
    axios
      .get(endpoint + `/grabJobOrderAndHistory/${jNum}`)
      .then(async response => {
        let data = response.data;
        // SELECTEDLOCATION
        await this.setState({
          selectedLocation: data,
          jobTypeId: data[0].jobTypeId,
          currJobId: data[0].id,
          jobNumber: data[0].job_number
        });
        this.searchInputTwo.value = null;

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleScanInput = async e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    // TODO: I think this will need to be fixed so that it only happens on search not on scan at all..
    // this.getSuggestions(e.target.value);
  };

  handleQTYInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  modalClose = () => {
    this.setState({
      modalShow: false,
      movePartModalShow: false,
      assemblePartModalShow: false,
      putBackInitialQuant: ""
    });
  }

  displayError = partSearchErrMsg => {
    console.log("NOTHING FOUND");
    this.setState({ partSearchErrMsg });
  };

  onLocationOptionChange = e => {
    const value = e.target.value;
    this.setState({
      newLocationId: value
    });
  };

  cancelEdit = () => {
    this.setState({ updateModalShow: false });
  };

  putBackModalShow = (putBackId, putBackPartNum, putBackQuant) => {
    this.setState({
      movePartModalShow: true,
      modalId: putBackId,
      modalPartNum: putBackPartNum,
      modalQuant: putBackQuant,
      modalInitialQuant: putBackQuant
    });
  };

  putBackPart = () => {
    if (!parseFloat(this.state.modalQuant))
      return this.setState({ movePartModalShow: false });

    axios
      .put(endpoint + "/updatePartOnAJob", {
        partTableId: this.state.modalId,
        putBackPartNum: this.state.modalPartNum,
        putBackQuant: this.state.modalQuant,
        jobNumber: this.state.jobNumber
      })
      .then(response => {
        this.jobS(this.state.jobNumber);
        this.setState({
          movePartModalShow: false,
          modalId: 0
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  assembleModalShow = (assemblePartId, assemblePartNum, assemblePartQuant) => {
    this.setState({
      assemblePartModalShow: true,
      modalId: assemblePartId,
      modalPartNum: assemblePartNum,
      modalQuant: assemblePartQuant,
      modalInitialQuant: assemblePartQuant
    });
  };

  assemblePart = () => {
    const { modalId, modalPartNum, modalQuant, modalInitialQuant } = this.state;

    if (!parseFloat(modalQuant))
      return this.setState({ assemblePartModalShow: false });

    const assembleData = {
      modalId,
      modalQuant,
      modalInitialQuant
    };

    axios
      .put(endpoint + "/assemble/update-part", assembleData)
      .then(response => {
        this.jobS(this.state.jobNumber);
        this.setState({
          assemblePartModalShow: false,
          modalId: 0,
          modalPartNum: "",
          modalQuant: "",
          modalInitialQuant: ""
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  searchAllParts = e => {
    if (e.target.value.length && this.state.addingPartBool) {
      axios
        .get(endpoint + `/searchAllPartsByNumber/${e.target.value}`)
        .then(response => { })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      console.log("Nothing found");
    }
  };

  // used on listJobs 708
  returnJobType = jobTypeId => {
    let jobTypeString;
    switch (jobTypeId) {
      case 1:
        jobTypeString = "Repair";
        break;
      case 2:
        jobTypeString = "Custom";
        break;
      case 3:
        jobTypeString = "Sample";
        break;
      case 4:
        jobTypeString = "Part";
        break;
      case 5:
        jobTypeString = "Finished";
        break;
      default:
        jobTypeString = null;
        break;
    }
    return jobTypeString;
  };

  handleGrabJob = () => {
    this.jobS(this.state.jobId)
  }

  render() {
    let location, parts;
    if (this.state.selectedLocation) {
      location = this.state.selectedLocation[0].location;
      parts = this.state.selectedLocation[0].parts;
    }

    return (
      <React.Fragment>
        <NavButtons toggleJobsBtn={this.state.navbarColor} />
        <div id="searchJobInfoDiv">
          <Button
            variant="secondary"
            id="jobNumButton"
            style={
              this.state.jobTypeId
                ? {
                  background: "#454d55",
                  width: "10%",
                  fontWeight: "bolder",
                  flexBasis: '20%'
                }
                : { visibility: "hidden" ,flexBasis: '20%'}
            }
          >
            {`${this.returnJobType(this.state.jobTypeId)} Job`}
            <Badge
              variant="light"
              style={{
                background: "#bba3d0",
                width: "100%",
                color: "white",
                fontWeight: "bold",
                fontSize: "100%"
              }}
            >
              {this.state.jobNumber ? this.state.jobNumber : null}
            </Badge>
            <Link
              to={{
                pathname: "/editJob",
                state: {
                  jobNumber: this.state.jobNumber,
                  jobId: this.state.selectedLocation
                    ? this.state.selectedLocation[0].id
                    : null,
                  jobData: this.state.selectedLocation
                    ? this.state.selectedLocation[0]
                    : null
                }
              }}
            >
              <button
                style={{
                  borderRadius: ".25rem",
                  border: " 1px solid white",
                  color: "#bba3d0",
                  fontWeight: "bold"
                }}
                disabled={!this.state.jobNumber}
              >
                Edit Job
              </button>
            </Link>
          </Button>

          <form
            className="searchForm"
            // autocomplete="off"
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <div className="suggestParentTwo">
              {this.state.partsArr.length ? (
                <div id="flash">
                  {this.state.partsArr.length === 1
                    ? `Part waiting to be added ${this.state.partsArr[0]}`
                    : this.state.partsArr.length > 1
                      ? `Part waiting to be added ${
                      this.state.partsArr[this.state.partsArr.length - 1]
                      }`
                      : null}
                </div>
              ) : null}

              <input
                style={{
                  padding: '.4rem'
                }}
                list="search"
                autoComplete="off"
                ref={input => { this.searchInputTwo = input; }}
                onChange={this.handleScanInput}
                name={this.state.addingPartBool ? "partId" : "jobId"}
                placeholder={
                  !this.state.addingPartBool ? "Job Number" : "Part Number"
                }
              />

              <datalist
                id="search">
                {
                  this.state[this.state.addingPartBool ? "allPartNumbers" : "allJobNumbers"].map(item => {
                    return <option value={item[this.state.addingPartBool ? "partnumber" : "job_number"]} />
                  })
                }
              </datalist>

              {/* {this.renderSuggestions()} */}
              {/* Display an error due to no search results/suggestions */}
              <div
                style={{
                  visibility:
                    this.state.partSearchErrMsg && this.state.partId
                      ? "visible"
                      : "hidden"
                }}
              >
                <ErrorMessage error={this.state.partSearchErrMsg} />
              </div>
            </div>
            <div style={{ width: '100%', display: "flex", justifyContent: "space-between" }}>
              {this.state.addingPartBool ? (
                <div>
                  <input
                    style={{
                      //padding: '.4rem'
                    }}
                    id="smallQTYInput"
                    placeholder="QTY"
                    name="partQTY"
                    autoComplete="off"
                    value={this.state.partQTY}
                    onChange={this.handleQTYInput}
                  />
                  <Button
                    style={{
                      padding: '.4rem'
                    }}
                    id="searchJobInfoButton"
                    onClick={this.handleAddPartButton}
                  >
                    Add
                </Button>
                </div>
              ) :
                <div>
                  <Button
                    style={{
                      padding: '.4rem'
                    }}
                    id="searchJobInfoButton"
                    onClick={this.handleGrabJob}
                  >
                    Open
          </Button>
                </div>}


            </div>
          </form>
          <div style={{flexBasis: '33%'}} >
          {this.state.selectedLocation && 
            <Alert key={'notesAlert'} variant={'secondary'} style={{border: '#454d55 solid 1px'}}>
              <b style={{color: '#bba3d0', border: '#454d55 solid 1px', padding: '.3rem', borderRadius: '.25rem', marginRight: '.3rem' }}>Notes:</b>{this.state.selectedLocation[0].notes}
          </Alert>}
          </div>
          
        </div>

        <DeleteModal
          show={this.state.modalShow}
          onHide={this.modalClose}
          msg={this.state.errMsg}
        />

        {this.state.selectedLocation &&
          this.state.selectedLocation[0].jobTypeId === 3 ? (
            <div className="tableWrapper">
              <Table striped bordered hover variant="dark" className="rounded">
                <thead>
                  <tr>
                    <th width="50px">Date Started</th>
                    <th width="50px">Finish Date</th>
                    <th width="50px">Style</th>
                    <th width="50px">Stone</th>
                    <th width="200px">Description</th>
                    <th width="50px">Band</th>
                    <th width="50px">Stone Price</th>
                    <th width="70px">Last Location</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.selectedLocation.length > 0 ? (
                    <tr>
                      <td>
                        {format(
                          this.state.selectedLocation[0].date_started,
                          "MM-DD-YYYY"
                        )}
                      </td>
                      <td>
                        {format(
                          this.state.selectedLocation[0].finish_date,
                          "MM-DD-YYYY"
                        )}
                      </td>
                      <td>{this.state.selectedLocation[0].style}</td>
                      <td>{this.state.selectedLocation[0].stone}</td>
                      <td>{this.state.selectedLocation[0].description}</td>
                      <td>{this.state.selectedLocation[0].band}</td>
                      <td>{this.state.selectedLocation[0].stone_price}</td>
                      <td>
                        {this.state.selectedLocation[0].location
                          ? this.state.selectedLocation[0].location.location_title
                          : null}
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </Table>
            </div>
          ) : null}

        <div id="jobInfo-SecondRow">
          <div
            className="jobInfo-table-wrapper"
            style={{ height: "60vh", overflow: "auto" }}
          >
            <div id="jobInfoTableWrapper">
              <Table
                striped
                bordered
                hover
                variant="dark"
                className="rounded"
                responsive
              >
                <thead>
                  <tr>
                    <th>
                      <Tooltipped>Part Number</Tooltipped>
                    </th>
                    <th>
                      <Tooltipped>Description</Tooltipped>
                    </th>
                    <th>
                      <Tooltipped>Qty</Tooltipped>
                    </th>
                    <th className="addBttnTh">
                      <Tooltipped>Price</Tooltipped>
                      <div>
                        <button
                          id="addPartBttn"
                          onClick={this.addPartOnclick}
                          disabled={this.state.jobNumber === 0 ? true : false}
                        >
                          {this.state.addingPartBool ? "Done" : "+"}
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.selectedLocation
                    ? this.state.selectedLocation[0].parts.map(item => {
                      return (
                        <tr key={item.id}>
                          <td>
                            {item.material ? (
                              <Link
                                style={{ color: "#bba3d0" }}
                                to={{
                                  pathname: "/Parts",
                                  state: {
                                    partNumber: item.material.partnumber
                                  }
                                }}
                              >
                                <Tooltipped>
                                  {item.material.partnumber}
                                </Tooltipped>
                              </Link>
                            ) : null}
                          </td>
                          <td>
                            <Tooltipped>
                              {item.material
                                ? item.material.description
                                : null}
                            </Tooltipped>
                          </td>
                          <td>{item.quantity ? item.quantity : null}</td>
                          <td>
                            $
                              {item.material
                              ? (
                                item.material.wholesaleprice * item.quantity
                              ).toFixed(2)
                              : null}
                            <div className="editDiv">
                              <button
                                onClick={() =>
                                  this.assembleModalShow(
                                    item.id,
                                    item.material.partnumber,
                                    item.quantity
                                  )
                                }
                                className="editButtons"
                              >
                                <GoTools />
                              </button>
                              <button
                                className="editButtons"
                                onClick={() =>
                                  this.putBackModalShow(
                                    item.id,
                                    item.material.partnumber,
                                    item.quantity
                                  )
                                }
                              >
                                <FaShare />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                    : null}
                </tbody>
              </Table>
            </div>
          </div>

          {/* Beginning of location history */}

          <div id="scanWrapper" style={{ overflow: "auto" }}>
            <h1 id="timeLineH1">
              <b>Scan</b> <span>History</span>
            </h1>
            <hr />

            <VerticalTimeline>
              {this.state.selectedLocation
                ? this.state.selectedLocation[1].map((item, i) => {
                  return (
                    <VerticalTimelineElement
                      key={i}
                      className="vertical-timeline-element--work"
                      contentStyle={{ background: "#454d55", color: "gray" }}
                      contentArrowStyle={{ borderRight: "7px solid #bba3d0" }}
                      date={format(item.date_entered, "MM-DD-YYYY")}
                    >
                      <h4 style={{ textAlign: "center" }}>
                        {item.location ? (
                          <Link
                            to={{
                              pathname: "/searchLocation",
                              state: {
                                locationId: item.location.id
                              }
                            }}
                            style={{ color: "#bba3d0" }}
                          >
                            {item.location.location_title}
                          </Link>
                        ) : (
                            <strike>No Location</strike>
                          )}
                      </h4>
                    </VerticalTimelineElement>
                  );
                })
                : null}
            </VerticalTimeline>
          </div>
        </div>

        <MovePartModal
          show={this.state.movePartModalShow}
          onHide={this.modalClose}
          modalFunction={this.putBackPart}
          modalPartNum={this.state.modalPartNum}
          modalQuant={this.state.modalQuant}
          modalInitialQuant={this.state.modalInitialQuant}
          handleQTYInput={this.handleQTYInput}
        />

        <MovePartModal
          show={this.state.assemblePartModalShow}
          onHide={this.modalClose}
          isAssemble
          modalFunction={this.assemblePart}
          modalPartNum={this.state.modalPartNum}
          modalQuant={this.state.modalQuant}
          handleQTYInput={this.handleQTYInput}
          modalInitialQuant={this.state.modalInitialQuant}
        />

        <Link
          to={{
            pathname: "/estimates",
            jobInfoPartsArr: !this.state.selectedLocation
              ? []
              : this.state.selectedLocation[0].parts
          }}
        >
          <button
            style={{
              marginLeft: "3.5%",
              marginTop: "1.5%",
              borderRadius: ".25rem",
              border: " 2px solid #454d55",
              color: "#bba3d0",
              fontWeight: "bold"
            }}
          >
            Create Recipe
          </button>
        </Link>
      </React.Fragment>
    );
  }
}

const ErrorMessage = props => {
  return (
    <em>
      <p style={{ color: "red", textAlign: "center" }}>{props.error}</p>
    </em>
  );
};

export default locationsTable;
