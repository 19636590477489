import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";


class MyVerticallyCenteredModal extends React.Component {

  linkToTables = () => {

  }

  render() {




    return (
      <div>
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          
        >


          <div variant="secondary" style={{display: "flex", flexDirection: "column", padding: "5%"}}>
            <h4 style={{textAlign:"center", color: "black"}}>{this.props.msg}</h4>
            {/* {this.props.locationTitle} */}
            <br/>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button onClick={this.props.onHide} style={{ backgroundColor: "#454d55", border: "none" }}>Go Back</Button>
            </div>
          </div>


          


        </Modal>

      </div>
    );
  }
}

export default MyVerticallyCenteredModal;