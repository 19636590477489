import React from "react";
import NavButtons from "../../navBtns";
import { Button, FormControl, Form, Modal } from "react-bootstrap";

import MyVerticallyCenteredModal from "../../modals/successLocationModal";
import { endpoint } from "../../../utils/endpoint";
import "./style.css";

import axios from "axios";

const styles = {
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  errorMsg: {
    margin: "25px 0",
    paddingBottom: 10
  },
  confirm: {
    borderRadius: "0.25rem",
    width: 140,
    color: "white",
    backgroundColor: "rgb(160, 110, 204)",
    fontWeight: "bold",
    border: "#454d55 solid 1px",
    padding: "0.6%"
  }
};

class createLocations extends React.Component {
  state = {
    navBtnColor: "",
    modalShow: false,
    errorModalOpen: false,
    locationName: "",
    locationType: 1,
    createdLocationId: 0
  };

  componentDidMount() {
    this.setState({ navBtnColor: "#454d55" });
  }

  handleLocationInput = event => {
    let locationName = event.target.value;
    this.setState({
      locationName
    });
  };

  handleLocationType = event => {
    let locationType = event.target.value;
    this.setState({
      locationType
    });
  };

  // submitLocation = () => {
  //   this.createNewLocation();
  // };

  createNewLocation = () => {
    axios
      .post(endpoint + "/createLocation", {
        locationTitle: this.state.locationName,
        locationTypeId: this.state.locationType
      })
      .then(response => {
        console.log("Grab that QR code ID", response.data.id);

        this.setState({
          createdLocationId: response.data.id,
          modalShow: true,
          locationName: ""
        });
      })
      .catch(error => {
        this.setState({ errorModalOpen: true });
      });
  };

  toggleErrorModal = () =>
    this.setState({ errorModalOpen: !this.state.errorModalOpen });

  render() {
    let modalClose = () => this.setState({ modalShow: false });

    return (
      <React.Fragment>
        <NavButtons toggleBtn={this.state.navBtnColor} />
        <div id="formBackground">
          <div id="locationInputDiv">
            <label className="locationLabel">New location name:</label>
            <FormControl
              className="locationInput"
              type="text"
              placeholder="Ex: Samples"
              value={this.state.locationName}
              onChange={this.handleLocationInput}
            />

            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label className="locationLabel">Location Type:</Form.Label>
              <Form.Control
                as="select"
                className="locationInput locationSelect"
                defaultValue="Job"
                value={this.state.locationType}
                onChange={this.handleLocationType}
              >
                <option value={1}>Job</option>
                <option value={2}>Parts</option>
              </Form.Control>
            </Form.Group>

            {/* May need input and select list in a form div */}
          </div>
        </div>

        <Button
          variant="outline-secondary"
          id="createButton"
          onClick={this.createNewLocation}
        >
          Create location
        </Button>
        <MyVerticallyCenteredModal
          show={this.state.modalShow}
          onHide={modalClose}
          location={this.state.createdLocationId || ""}
        />

        <Modal
          show={this.state.errorModalOpen}
          onHide={this.toggleErrorModal}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Error!</Modal.Title>
          </Modal.Header>
          <Modal.Body
          // style={styles.modal}
          >
            <div style={styles.modalContainer}>
              <h4 style={styles.errorMsg}>
                "<strong>{this.state.locationName}</strong>" already exists.
                Please try again.{" "}
              </h4>
              <button
                id="addExtraBttn"
                style={styles.confirm}
                onClick={this.toggleErrorModal}
              >
                Confirm
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default createLocations;
