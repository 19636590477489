import React from "react";
import NavButtons from "../../navBtns";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import MyVerticallyCenteredModal from "../../modals/successPartModal";
import "./style.css";
import axios from "axios";
import UpdateModal from "../../modals/UpdatedPartModal";
import DeleteModal from "../../modals/deleteModal/DeleteModal";
import ReprintQr from "../../modals/rePrintPartQR";
import { endpoint } from "../../../utils/endpoint";

class createLocations extends React.Component {
  state = {
    navBtnColor: "",
    modalShow: false,
    partDataToUpdate: [],
    partNumber: 0,
    description: "",
    weight: 0,
    metalCost: 0,
    castingCost: 0,
    internalCost: 0,
    wholeSalePrice: 0,
    notes: "",
    quantity: 0,
    metaltype: "",
    partLocationsTableId: 1,
    imgLink: "www.",
    suggestions: [],
    partLocations: [],
    partLocationsTableId: 4,
    locationId: 0,
    deleteModalShow: false,
    deleteModalOpen: false,
    errMsg: "",
  };

  componentDidMount() {
    if (!this.props.location.state) return this.props.history.push("/Parts");

    this.setState({ navBtnColor: "#454d55" });
    this.grabPartToUpdate(this.props.location.state.id);
    // this.grabJobLocations();
  }

  handleInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  grabPartToUpdate = async id => {
    let res = await axios.get(endpoint + `/grabMaterialToUpdate/${id}`);
    let data = res.data;
    this.setState({
      partNumber: data[0].partnumber,
      description: data[0].description,
      weight: data[0].material_purchased.weight,
      metalCost: data[0].material_purchased.metalcost,
      castingCost: data[0].material_purchased.castingcost,
      internalCost: data[0].material_purchased.internalcost,
      wholeSalePrice: data[0].wholesaleprice,
      quantity: data[0].material_inventory.quantitycurrent,
      materialInventoryId: data[0].materialInventoryId,
      materialPurchasedId: data[0].materialPurchasedId,
      notes: data[0].material_purchased.notes,
      materialId: data[0].id,
      metaltype: data[0].material_purchased.metaltype
    });
  };

  onTextChange = e => {
    const value = e.target.value;
    this.getSuggestions(value);
    console.log(this.state.suggestions);
  };

  getSuggestions = async partName => {
    if (partName.length > 0) {
      let arrayOfNumbers = [];
      let res = await axios.get(
        endpoint + `/searchForPartLocation/${partName}`
      );
      let data = res.data;
      this.setState({ suggestions: data });
    } else {
      this.setState({ suggestions: [] });
    }
  };

  renderSuggestions() {
    const { suggestions } = this.state;
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <div className="suggestions-ul">
        {suggestions.map(item => (
          <div
            className="suggestListI"
            onClick={() => this.selectionLocation(item.id, item.locationTitle)}
          >
            {item.locationTitle}
          </div>
        ))}
      </div>
    );
  }

  handleState = e => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleJobType = event => {
    let jobType = event.target.value;
    this.setState({
      jobType
    });
  };

  renderSuggestions() {
    const { suggestions } = this.state;
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <div className="suggestions-ul">
        {/* TODO: Choose if this should render the partLocTable or the jobLoc table */}
        {suggestions.map(item => (
          <div
            className="suggestListI"
            onClick={() =>
              this.selectionLocation(item.id, item.partLocationTitle)
            }
          >
            {item.partLocationTitle}
          </div>
        ))}
      </div>
    );
  }

  selectionLocation = (id, locationName) => {
    var partLocInput = document.getElementById("partLocationID");
    partLocInput.value = locationName;
    this.setState({
      partLocationsTableId: id
    });
    this.setState({
      suggestions: []
    });
    console.log(id);
  };

  updatePart = e => {
    e.preventDefault();
    axios
      .put(endpoint + "/updateMaterial", {
        partNumber: this.state.partumber,
        description: this.state.description,
        weight: this.state.weight,
        metalCost: this.state.metalCost,
        castingCost: this.state.castingCost,
        internalCost: this.state.internalCost,
        wholeSaleCost: this.state.wholeSalePrice,
        notes: this.state.notes,
        quantity: this.state.quantity,
        materialInventoryId: this.state.materialInventoryId,
        materialPurchasedId: this.state.materialPurchasedId,
        id: this.state.materialId,
        metaltype: this.state.metaltype
        // locationId: this.state.locationId,
        // imgLink: this.state.imgLink,
      })
      .then(response => {
        this.setState({
          deleteModalShow: true,
          errMsg: "Updated Successfully"
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  deletePart = e => {
    axios
      .delete(endpoint + "/deleteMaterial", {
        data: {
          materialInventoryId: this.state.materialInventoryId,
          materialPurchasedId: this.state.materialPurchasedId,
          id: this.state.materialId
        }
      })
      .then(response => {
        this.setState({
          deleteModalShow: true,
          deleteModalOpen: false,
          errMsg: response.data
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  showModal = e => {
    e.preventDefault();
    this.setState({ modalShow: true });
  };

  toggleDeleteModal = () => {
    this.setState({ deleteModalOpen: !this.state.deleteModalOpen });
  };

  modalClose = () => {
    this.setState({ modalShow: false, deleteModalShow: false });
    this.props.history.push("/Parts");
  };

  render() {
    return (
      <React.Fragment>
        <NavButtons togglePartsBtn={this.state.navBtnColor} />
        <form onSubmit={this.createNewPart}>
          <div className="containerDiv" style={{ paddingTop: 20 }}>
            <h2
              style={{
                borderLeft: "solid #bba3d0 2px",
                paddingLeft: "1.5%",
                marginBottom: 30
              }}
            >
              Edit Part
            </h2>

            <div className="inputDivOne">
              <div className="dateInputDiv">
                <label className="jobsLabel">Part #</label>
                <FormControl
                  className="dateInputBox jobsInput"
                  type="text"
                  name="partNumber"
                  autoComplete="off"
                  onChange={this.handleState}
                  value={this.state.partNumber}
                  disabled
                />
              </div>
              <div className="dateInputDiv">
                <label className="jobsLabel">Description</label>
                <FormControl
                  className="dateInputBox jobsInput"
                  type="text"
                  name="description"
                  autoComplete="off"
                  onChange={this.handleState}
                  value={this.state.description}
                />
              </div>
            </div>

            <div className="inputDivOne">
              <div className="jobInputDiv">
                <label className="jobsLabel">Weight</label>
                <FormControl
                  className="jobsInput"
                  type="text"
                  placeholder=""
                  name="weight"
                  autoComplete="off"
                  onChange={this.handleState}
                  value={this.state.weight}
                />
              </div>

              <div className="jobInputDiv">
                <label className="jobsLabel">Metal Cost</label>
                <FormControl
                  className="jobsInput"
                  type="text"
                  placeholder=""
                  name="metalCost"
                  autoComplete="off"
                  onChange={this.handleState}
                  value={this.state.metalCost}
                />
              </div>
            </div>

            <div className="inputDivOne">
              <div className="jobInputDiv">
                <label className="jobsLabel">Casting Labor Cost</label>
                <FormControl
                  className="jobsInput"
                  type="text"
                  placeholder=""
                  name="castingCost"
                  autoComplete="off"
                  onChange={this.handleState}
                  value={this.state.castingCost}
                />
              </div>

              <div className="jobInputDiv">
                <label className="jobsLabel">Internal Labor Cost</label>
                <FormControl
                  className="jobsInput"
                  type="text"
                  placeholder=""
                  name="internalCost"
                  autoComplete="off"
                  onChange={this.handleState}
                  value={this.state.internalCost}
                />
              </div>
            </div>

            <div className="inputDivOne">
              <div className="jobInputDiv">
                <label className="jobsLabel">Wholesale Price</label>
                <FormControl
                  className="jobsInput"
                  type="text"
                  placeholder=""
                  name="wholeSalePrice"
                  autoComplete="off"
                  onChange={this.handleState}
                  value={this.state.wholeSalePrice}
                />
              </div>

              <div className="jobInputDiv">
                <label className="jobsLabel">Notes</label>
                <FormControl
                  className="jobsInput"
                  type="text"
                  placeholder=""
                  name="notes"
                  autoComplete="off"
                  onChange={this.handleState}
                  value={this.state.notes}
                />
              </div>
            </div>

            <div className="inputDivOne">
              <div className="jobInputDiv">
                <label className="jobsLabel">Quantity</label>
                <input
                  className="jobsInput"
                  type="text"
                  placeholder=""
                  name="quantity"
                  autoComplete="off"
                  onChange={this.handleState}
                  value={this.state.quantity}
                />
                <label className="jobsLabel">Metal type</label>
                <input
                  className="jobsInput"
                  type="text"
                  placeholder=""
                  name="metaltype"
                  autoComplete="off"
                  onChange={this.handleState}
                  value={this.state.metaltype}
                />
              </div>
            </div>
          </div>

          <div id="buttonDiv" style={{ justifyContent: "space-between" }}>
            <button
              id="createJobButton"
              type="submit"
              onClick={this.updatePart}
            >
              Update
            </button>
            <button
              id="createJobButton"
              onClick={e => {
                e.preventDefault();
                this.toggleDeleteModal();
              }}
            >
              Delete
            </button>
            <button id="createJobButton" onClick={this.showModal}>
              Reprint QR
            </button>
          </div>
        </form>
        <DeleteModal
          show={this.state.deleteModalOpen}
          onHide={this.toggleDeleteModal}
          handleDelete={this.deletePart}
          locationName={`${
            this.state.partNumber ? this.state.partNumber : ""
          } ${this.state.description ? " - " + this.state.description : ""} `}
          type="Part"
        />
        <ReprintQr
          show={this.state.modalShow}
          onHide={this.modalClose}
          partnumberforqr={this.state.partNumber}
        />

        <UpdateModal
          show={this.state.deleteModalShow}
          onHide={this.modalClose}
          msg={this.state.errMsg}
          partnumber={this.state.partNumber}
        />
      </React.Fragment>
    );
  }
}

export default createLocations;
