import React from "react";
import NavButtons from "./components/navBtns";
import SearchLocations from "./components/locationComponents/searchLocation";
import CreateLocation from "./components/locationComponents/createLocation";
import PartsLocation from "./components/partsComponents/partsLocation";
import JobsAddNew from "./components/jobsComponents/jobsAddNew";
import JobsList from "./components/jobsComponents/listJobs";
import Parts from "./components/partsComponents/parts";
import EditJob from "./components/jobsComponents/jobsEdit";
import EditLocation from "./components/locationComponents/editLocation";
import JobInfo from "./components/jobsComponents/jobInfo";
import AddPart from "./components/partsComponents/partsAddNew";
import EditPart from "./components/partsComponents/editPart";
import Estimate from "./components/estimatePage";
import { HashRouter, Route, Switch } from "react-router-dom";
import LoginPage from "./components/login";
import unAth from "./components/Unauthorized";

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
          <HashRouter>
            <Switch>
              <Route exact path="/" component={ LoginPage } />
              <Route exact path="/main" component={ localStorage.getItem('jjHint') ? NavButtons : unAth } />
              <Route exact path="/searchLocation" component={  SearchLocations } />
              <Route exact path="/createLocation" component={ localStorage.getItem('jjHint') ? CreateLocation : unAth } />
              <Route exact path="/partsLocation" component={ localStorage.getItem('jjHint') ? PartsLocation : unAth } />
              <Route exact path="/jobAdd" component={ localStorage.getItem('jjHint') ? JobsAddNew : unAth } />
              <Route exact path="/jobsList" component={ localStorage.getItem('jjHint') ? JobsList : unAth } />
              <Route exact path="/editJob" component={ localStorage.getItem('jjHint') ? EditJob : unAth } />
              <Route exact path="/editLocation" component={ localStorage.getItem('jjHint') ? EditLocation : unAth } />
              <Route exact path="/jobInfo" component={ localStorage.getItem('jjHint') ? JobInfo : unAth } />
              <Route exact path="/addParts" component={ localStorage.getItem('jjHint') ?AddPart : unAth } />
              <Route exact path="/Parts" component={ localStorage.getItem('jjHint') ? Parts : unAth } />
              <Route exact path="/estimates" component={ localStorage.getItem('jjHint') ? Estimate : unAth } />
              <Route exact path="/editPart" component={ localStorage.getItem('jjHint') ? EditPart : unAth } />
            </Switch>
          </HashRouter>
      </React.Fragment>
    );
  }
}
export default App;