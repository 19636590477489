import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// Custom
import NavButtons from "../navBtns";
import PartSearchInput from "./PartSearchInput";
import CustomPartInput from "./CustomPartInput";
import PartTable from "./PartTable";
import PrintTable from "./PrintTable";
import { endpoint } from "../../utils/endpoint";
// CSS
import { Container, ButtonGroup, Button, Modal } from "react-bootstrap";
import "./style.css";
import Axios from "axios";

const styles = {
  title: { marginTop: 20, marginBottom: 12, color: "#454d55" },
  activeBtn: {
    backgroundColor: "#bba3d0",
    borderColor: "#bba3d0"
  },
  disabledBtn: {
    backgroundColor: "#454d55",
    borderColor: "#454d55"
  },
  printBtn: {
    alignSelf: "center",
    backgroundColor: "#bba3d0",
    color: "white",
    borderRadius: "0.25rem",
    width: 100,
    fontWeight: "bold",
    border: " #454d55 solid 1px"
  },
  modalForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  label: {
    display: "flex",
    flexDirection: "column"
  },
  input: {
    // width: "20%",
    borderRadius: "0.25rem",
    border: "#454d55 solid 1px",
    marginRight: "2%",
    padding: "0.6%"
  },
  strikeThrough: {
    textDecoration: "line-through"
  },
  addBtn: {
    borderRadius: "0.25rem",
    width: "20%",
    fontWeight: "bold",
    border: " #454d55 solid 1px",
    marginLeft: "2%",
    padding: "0.6%"
  },
  addBtnActive: {
    backgroundColor: "#bba3d0",
    color: "white"
  },
  addBtnDisabled: {
    backgroundColor: "#454d55",
    color: "lightgrey"
  },
  partSearchContainer: {
    position: "relative"
  },
  suggestionsUl: {
    width: "75.3%",
    top: 40
  }

  //   suggestionsLi:{
  //     background: #fcf8ff;
  //     list-style: none;
  //     border: #454d55 .5px solid;
  //     border-radius: .35rem;
  //     text-align: center;
  //     margin: .12rem;
  //     cursor: pointer;
  //   }
};

class Estimates extends React.Component {
  state = {
    showCustomPartInput: false,
    activePartForm: "search",
    partNum: "",
    partQty: 0,
    customPartNum: "",
    customDesc: "",
    customQty: "",
    customPrice: 0,
    parts: [],
    stagedParts: [],
    scanSearchResult: [],
    suggestions: [],
    isModalOpen: false,
    editPartNum: "",
    editDesc: "",
    editQty: "",
    editPrice: 0,
    displayErrorModal: false,
    errPartNum: ""
  };
  inputFocusRef = React.createRef();
  qtyFocusRef = React.createRef();

  componentDidMount() {
    // add event listener for scanning
    window.addEventListener("keydown", this.handleEnterPress, false);

    // focus on the partSearch input
    this.inputFocusRef.current.focus();

    // get props from user clicking Create Recipe on jobInfo
    const { jobInfoPartsArr } = this.props.location;

    if (jobInfoPartsArr) {
      const parts = jobInfoPartsArr.map(part => {
        return {
          desc:
            part.material && part.material.description
              ? part.material.description
              : "",
          partNum:
            part.material && part.material.partnumber
              ? part.material.partnumber
              : "",
          price: 
            part.material && part.material.wholesaleprice
              ? (part.material.wholesaleprice * part.quantity).toFixed(2)
              : "0"
          ,
          qty: part.quantity || "0"
        };
      });

      this.setState({ parts });
    }

    this.setState({ navbarColor: "#454d55" });
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleEnterPress, false);
  }

  handleEnterPress = async event => {
    if (event.key === "Enter") {
      // await this.setState({ suggestions: [] });
      this.qtyFocusRef.current.focus();
      // this.getSuggestions(this.state.partNum);

      // const scannedItem = { ...this.state.suggestions[0] };

      // await this.setState({
      //   parts: [
      //     ...this.state.parts,
      //     {
      //       desc: scannedItem.description,
      //       partNum: scannedItem.partnumber,
      //       price: parseFloat(scannedItem.wholesaleprice),
      //       qty: 1
      //     }
      //   ],
      //   partNum: "",
      //   suggestions: []
      // });

      // this.inputFocusRef.current.focus();
    }
  };

  handleToggleModal = (part, index) => {
    if (!this.state.isModalOpen) {
      const { partNum, desc, qty, price } = part;

      this.setState({
        editIndex: index,
        editPartNum: partNum,
        editDesc: desc,
        editQty: qty,
        // changed to display Total price: qty * price
        editPrice: price,
        // editPrice: parseFloat(price) * parseFloat(qty),
        isModalOpen: true
      });
    } else {
      this.setState({
        editIndex: "",
        editPartNum: "",
        editDesc: "",
        editQty: "",
        editPrice: 0,
        isModalOpen: false
      });
    }
  };

  handleInputChange = async e => {
    const { name, value } = e.target;

    this.setState({ [name]: value });

    if (name === "partNum" && value === "") {
      this.setState({
        suggestions: [],
        displayErrorModal: false,
        errPartNum: ""
      });
    }

    if (name === "partNum" && value.length >= 1) {
      this.getSuggestions(value);
      this.setState({
        displayErrorModal: false,
        errPartNum: "",
        suggestions: []
      });
    }
  };

  handleDisplayCustomInput = e => {
    const { name } = e.target;
    if (name === this.state.activePartForm) return;
    this.setState({
      showCustomPartInput: !this.state.showCustomPartInput,
      activePartForm: name,
      partNum: "",
      partQty: "",
      customPartNum: "000",
      customDesc: "",
      customQty: "",
      customPrice: ""
    });
  };

  setPartToAdd = ({ partnumber, description, wholesaleprice }) => {
    this.setState({
      partNum: partnumber,
      selectedDesc: description,
      selectedWholeSale: wholesaleprice || 0,
      suggestions: []
    });
    this.qtyFocusRef.current.focus();
  };

  handleAddPart = () => {
    const { partNum, selectedDesc, selectedWholeSale, partQty } = this.state;

    if (!selectedDesc) {
      this.getSuggestions(this.state.partNum);

      const scannedItem = { ...this.state.suggestions[0] };

      if (!scannedItem.id) {
        this.inputFocusRef.current.focus();
        return this.setState({
          displayErrorModal: true,
          errPartNum: partNum
        });
      }

      this.setState({
        parts: [
          ...this.state.parts,
          {
            partNum: scannedItem.partnumber,
            desc: scannedItem.description,
            qty: partQty,
            price: (
              parseFloat(partQty) * parseFloat(scannedItem.wholesaleprice || 0)
            ).toFixed(2)
          }
        ],
        partNum: "",
        selectedDesc: "",
        selectedWholeSale: "",
        partQty: "",
        suggestions: [],
        displayErrorModal: false
      });
      this.inputFocusRef.current.focus();
    } else {
      this.setState({
        parts: [
          ...this.state.parts,
          {
            partNum: partNum,
            desc: selectedDesc,
            qty: partQty,
            price:
              (parseFloat(partQty) * parseFloat(selectedWholeSale)).toFixed(
                2
              ) || 0
          }
        ],
        suggestions: [],
        partNum: "",
        selectedDesc: "",
        selectedWholeSale: "",
        partQty: "",
        displayErrorModal: false
      });
      this.inputFocusRef.current.focus();
    }
  };

  handleAddCustom = () => {
    const { customPartNum, customDesc, customQty, customPrice } = this.state;

    this.setState({
      parts: [
        ...this.state.parts,
        {
          partNum: customPartNum,
          desc: customDesc,
          qty: customQty,
          price: (+customPrice).toFixed(2)
        }
      ],
      customPartNum: "000",
      customDesc: "",
      customQty: "0",
      customPrice: "0"
    });
  };

  handleRemovePart = chosenIndex => {
    const filteredParts = this.state.parts.filter(
      (part, index) => index !== chosenIndex
    );
    this.setState({ parts: filteredParts });
  };

  totalParts = (parts = this.state.parts) => {
    return parts
      .reduce((sum, part) => {
        return sum + parseFloat(part.price);
      }, 0)
      .toFixed(2);
  };

  getSuggestions = async term => {
    if (!term) return console.log("NO TERM");

    let res = await Axios.get(
      endpoint + `/searchAllPartsByNumberAndDesc/${term}`
    ).catch(err => console.log(err));

    //if no suggestions are found, display the error
    if (!res.data.length) {
      return this.setState({
        displayErrorModal: true,
        errPartNum: term,
        suggestions: []
      });
    } else {
      // otherwise populate suggestions
      this.setState({ suggestions: res.data });
    }
  };

  renderSuggestions = () => {
    if (this.state.suggestions.length === 0) {
      return null;
    }

    return this.state.suggestions.map(item => {
      return (
        <div
          key={item.id}
          className="suggestListI"
          style={{ border: "1px solid #a06ecc" }}
          onClick={() => this.setPartToAdd(item)}
        >
          {`${item.partnumber} - ${item.description}`}
        </div>
      );
    });
  };

  handlePartUpdate = () => {
    const { editIndex, editPartNum, editDesc, editQty, editPrice } = this.state;
    const partsCopy = [...this.state.parts];
    partsCopy[editIndex] = {
      partNum: editPartNum,
      desc: editDesc,
      price: parseFloat(editPrice),
      qty: editQty
    };

    this.setState({ parts: partsCopy }, this.handleToggleModal);
  };

  // printTable = () => {
  //   // print("estimate-table", "html");
  //   print("print-table", "html");
  // };

  render() {
    const {
      showCustomPartInput,
      partNum,
      partQty,
      customPartNum,
      customDesc,
      customQty,
      customPrice,
      parts,
      editPartNum,
      editDesc,
      editQty,
      editPrice
    } = this.state;
    const disabled = !editPartNum || !editDesc || !editQty || !editPrice;

    return (
      <React.Fragment>
        <NavButtons toggleEstButton="#454d55" />
        <Container>
          <h4 style={styles.title}>Create an estimate:</h4>
          <ButtonGroup aria-label="Basic example">
            <Button
              name="search"
              style={
                showCustomPartInput ? styles.disabledBtn : styles.activeBtn
              }
              onClick={this.handleDisplayCustomInput}
            >
              Search Parts
            </Button>
            <Button
              name="custom"
              style={
                showCustomPartInput ? styles.activeBtn : styles.disabledBtn
              }
              onClick={this.handleDisplayCustomInput}
            >
              Custom Row
            </Button>
          </ButtonGroup>

          <div style={{ marginTop: "20px" }}>
            {!showCustomPartInput ? (
              <div style={styles.partSearchContainer}>
                <PartSearchInput
                  handleInputChange={this.handleInputChange}
                  handleAddPart={this.handleAddPart}
                  partNum={partNum}
                  partQty={partQty}
                  inputFocusRef={this.inputFocusRef}
                  qtyFocusRef={this.qtyFocusRef}
                  suggestions={this.state.suggestions}
                  renderSuggestions={this.renderSuggestions}
                />
                {this.state.displayErrorModal ? (
                  <ErrorMessage>
                    Nothing Part found for {this.state.errPartNum}.
                  </ErrorMessage>
                ) : null}
                <div className="suggestions-ul" style={styles.suggestionsUl}>
                  {this.state.partNum && this.renderSuggestions()}
                </div>
              </div>
            ) : (
              <CustomPartInput
                handleInputChange={this.handleInputChange}
                handleAddCustom={this.handleAddCustom}
                customPartNum={customPartNum}
                customDesc={customDesc}
                customQty={customQty}
                customPrice={customPrice}
              />
            )}

            <div>
              <PartTable
                toggleModal={this.handleToggleModal}
                handleRemovePart={this.handleRemovePart}
                parts={parts}
              />
              <Modal
                show={this.state.isModalOpen}
                onHide={this.handleToggleModal}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Update Part Details</Modal.Title>
                </Modal.Header>
                <Modal.Body style={styles.modal}>
                  {/* editPartNum, editDesc,editQty,editPrice, */}

                  <div style={styles.modalForm}>
                    <label style={styles.label}>
                      Part #:
                      <input
                        type="text"
                        value={editPartNum}
                        name="editPartNum"
                        autoComplete="off"
                        style={
                          editPartNum === "000" || editPartNum === ""
                            ? { ...styles.input, ...styles.strikeThrough }
                            : styles.input
                        }
                        onChange={this.handleInputChange}
                      />
                    </label>

                    <label style={styles.label}>
                      Desc:
                      <input
                        type="text"
                        value={editDesc}
                        name="editDesc"
                        autoComplete="off"
                        style={styles.input}
                        onChange={this.handleInputChange}
                      />
                    </label>
                    <label style={styles.label}>
                      Qty:
                      <input
                        type="number"
                        value={editQty}
                        name="editQty"
                        autoComplete="off"
                        style={styles.input}
                        onChange={this.handleInputChange}
                      />
                    </label>
                    <label style={styles.label}>
                      Price:
                      <input
                        type="number"
                        value={editPrice}
                        name="editPrice"
                        autoComplete="off"
                        style={styles.input}
                        onChange={this.handleInputChange}
                      />
                    </label>

                    <button
                      id="addExtraBttn"
                      disabled={disabled}
                      style={
                        disabled
                          ? { ...styles.addBtn, ...styles.addBtnDisabled }
                          : { ...styles.addBtn, ...styles.addBtnActive }
                      }
                      onClick={this.handlePartUpdate}
                    >
                      Update
                    </button>
                  </div>
                  <br />
                </Modal.Body>
              </Modal>
            </div>
            {this.state.parts.length ? (
              <div id="bottomEstDiv">
                <div id="smallerDiv">
                  <label id="totalSpan">Total:</label>
                  {/* <input className="totalInput" placeholder="$" /> */}
                  <h4>${this.totalParts()}</h4>
                  {/* <button style={styles.printBtn} onClick={this.printTable}>
                  Print
                </button> */}
                  <div>
                    <ReactHTMLTableToExcel
                      id="download-spreadsheet"
                      table="print-table"
                      filename="estimate"
                      sheet="tablexls"
                      buttonText="Download"
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <PrintTable parts={parts} totalParts={this.totalParts} />
        </Container>
      </React.Fragment>
    );
  }
}

const ErrorMessage = props => {
  return (
    <em>
      <p style={{ color: "red", textAlign: "center" }}>{props.children}</p>
    </em>
  );
};

export default Estimates;
