import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function Tooltipped(props) {
  return (
    <OverlayTrigger
      center="center"
      overlay={<Tooltip>{props.children}</Tooltip>}
    >
      <p style={{ marginBottom: 0, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} variant="secondary">
        {props.children}
      </p>
    </OverlayTrigger>
  );
}
