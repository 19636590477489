import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class MovePartModal extends React.Component {
  // linkToTables = () => {};
  handleUpdateQuantity = e => {
    this.props.handleQTYInput(e);
  };
  render() {
    const {
      modalQuant,
      modalInitialQuant,
      modalPartNum,
      onHide,
      modalFunction,
      isAssemble
    } = this.props;

    return (
      <div>
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div
            variant="secondary"
            style={{ display: "flex", flexDirection: "column", padding: "5%" }}
          >
            <h4 style={{ textAlign: "center", color: "#454d55" }}>
              How many of <code style={{ color: "#bba3d0" }}>{modalPartNum}</code> would you like to
              {isAssemble ? " remove" : " put back"}?
            </h4>
            <label htmlFor="updateQuantity" style={{ textAlign: "center" }}>
              Quantity:
              <input
                type="number"
                id="updateQuantity"
                value={modalQuant}
                name="modalQuant"
                step=".001"
                min="0"
                style={{
                  width: 80,
                  marginLeft: 10
                }}
                onChange={this.handleUpdateQuantity}
              />
            </label>
            <br />
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                onClick={onHide}
                style={{ backgroundColor: "#454d55", border: "none" }}
              >
                Cancel
              </Button>

              <Button
                onClick={modalFunction}
                style={{ backgroundColor: "#454d55", border: "none" }}
                disabled={
                  modalQuant === "" ||
                  parseFloat(modalQuant) < 0 ||
                  parseFloat(modalQuant) > parseFloat(modalInitialQuant)
                    ? true
                    : false
                }
              >
                Continue
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default MovePartModal;
