import React from "react";
const styles = {
  container: {
    marginBottom: 33,
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },

  input: {
    // width: "20%",
    borderRadius: "0.25rem",
    border: "#454d55 solid 1px",
    marginRight: "2%",
    padding: "0.6%",
    flex: 1
  },
  strikeThrough: {
    textDecoration: "line-through"
  },
  addBtn: {
    borderRadius: "0.25rem",
    width: "12%",
    fontWeight: "bold",
    border: " #454d55 solid 1px",
    marginLeft: "2%",
    padding: "0.6%"
  },
  addBtnActive: {
    backgroundColor: "#a06ecc",
    color: "white"
  },
  addBtnDisabled: {
    backgroundColor: "grey",
    color: "lightgrey"
  }
};
export default function CustomPartInput(props) {
  const { customPartNum, customDesc, customQty, customPrice } = props;

  const disabled = !customPartNum || !customDesc || !customQty || !customPrice;

  return (
    <div style={styles.container}>
      <input
        type="text"
        value={props.customPartNum}
        name="customPartNum"
        autoComplete="off"
        placeholder="000"
        style={
          props.customPartNum === "000" || props.customPartNum === ""
            ? { ...styles.input, ...styles.strikeThrough }
            : styles.input
        }
        value={customPartNum}
        onChange={props.handleInputChange}
      />
      <input
        type="text"
        placeholder="Enter a description..."
        name="customDesc"
        autoComplete="off"
        style={styles.input}
        value={customDesc}
        onChange={props.handleInputChange}
      />
      <input
        type="number"
        placeholder="QTY"
        name="customQty"
        autoComplete="off"
        style={styles.input}
        value={customQty ? customQty : ""}
        onChange={props.handleInputChange}
      />
      <input
        type="number"
        placeholder="Price"
        name="customPrice"
        autoComplete="off"
        style={styles.input}
        value={customPrice ? customPrice : ""}
        onChange={props.handleInputChange}
      />

      <button
        id="addExtraBttn"
        disabled={disabled}
        style={
          disabled
            ? { ...styles.addBtn, ...styles.addBtnDisabled }
            : { ...styles.addBtn, ...styles.addBtnActive }
        }
        onClick={props.handleAddCustom}
      >
        ADD
      </button>
    </div>
  );
}
