let endpoint;

const useHeroku = true;

if (useHeroku) {
  endpoint = "https://jjservertest.herokuapp.com";
} else {
  endpoint = "http://localhost:8080";
}
export { endpoint };
