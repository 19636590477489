import React from 'react';

const unAth = () => {
    return (
        <React.Fragment>
            <div style={{ width: '100%', marginTop: '15%'}}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "column", border: 'solid 5px #bba3d0', borderRadius: '.55rem', padding: "8%", color: "#454d55" }}>
                        <h2>Not an authorized user.</h2>
                        <p>Please contact us <a href='https://www.jamiejoseph.com/contact' style={{color: "#bba3d0", fontWeight: 'bold'}}>Here</a> more information</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default unAth;