import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import QRCode from "qrcode.react";
import print from 'print-js';
import {Link} from 'react-router-dom';



class RePrintPartModal extends React.Component {

    printBarCode = () => {
        print('QRDiv', 'html');
    }

    render() {

        return (
            <div>
                <Modal
                    {...this.props}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div style={{marginTop: "5px"}}></div>
                    <div id="QRDiv" style={{ display: "flex", justifyContent: "center" }}>
                        <QRCode value={this.props.partnumberforqr.toString()} />
                    </div>
                    <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
                        <Button onClick={this.printBarCode} style={{ backgroundColor: "#bba3d0", border: "none" }}>Print Barcode</Button>
                        <Button onClick={this.props.onHide} style={{ backgroundColor: "#454d55", border: "none" }}>Go To Parts</Button>
                    </Modal.Footer>
                </Modal>



            </div>
        );
    }
}

export default RePrintPartModal;