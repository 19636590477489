import React from 'react';
import { MDBDataTable } from 'mdbreact';
import format from 'date-fns/format';
import { HashRouter, Route, Switch } from "react-router-dom";

const DatatablePage = (props) => {
  const returnJobType = jobTypeId => {
    let jobTypeString;
    switch (jobTypeId) {
      case 1:
        jobTypeString = "Repair";
        break;
      case 2:
        jobTypeString = "Custom";
        break;
      case 3:
        jobTypeString = "Sample";
        break;
      case 4:
        jobTypeString = "Part";
        break;
      case 5:
        jobTypeString = "Finished";
        break;
      default:
        jobTypeString = null;
        break;
    }
    return jobTypeString;
  };

  const clickedJob = jNum => {
    const { history } = props;
    history.push({
      pathname: "/jobInfo",
      state: {
        jobId: jNum
      }
    });
  };

  const tableData = props.data.map(item => {
    item.clickEvent = () => clickedJob(item.job_number);
    item.date_started = format(item.date_started, 'MM-DD-YYYY');
    item.finish_date = format(item.finish_date, 'MM-DD-YYYY');
    item.jobTypeId = returnJobType(item.jobTypeId);
    item.location = item.location ? item.location.location_title : null;

    return item
  });
  const data = {
    columns: [
      {
        label: 'Job Number',
        field: 'job_number',
        sort: 'asc',
        width: 0
      },
      {
        label: 'Date Started',
        field: 'date_started',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Finish Date',
        field: 'finish_date',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Type',
        field: 'jobTypeId',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Notes',
        field: 'notes',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Current Location',
        field: 'location',
        sort: 'asc',
        width: 100
      }
    ],
    rows: tableData

  };

  return (
    <MDBDataTable
      sortable
      striped
      bordered
      hover
      entriesLabel="Show entries"
      data={data}
    />
  );
}

export default DatatablePage;