import React from "react";
// import { useState, useEffect } from "react";
import "./style.css";
// import axios from "axios";
// import NavButtons from "../../navBtns";
// import Button from "react-bootstrap/Button";
// import FormControl from "react-bootstrap/FormControl";
// import Form from "react-bootstrap/Form";
// import { FiEdit } from "react-icons/fi";
// import { FaShare } from "react-icons/fa";
// import Badge from "react-bootstrap/Badge";
import Tooltipped from "../../tooltip/Tooltipped";
import { Table } from "react-bootstrap";
// import MyVerticallyCenteredModal from "../../modals/deleteQuestionModal";
import { Link, Redirect } from "react-router-dom";

const styles = {
  columnTitle: {
    color: "#a06ecc",
    textAlign: "center",
    // padding: "12px 5px"
    width: "5px"
  },
  partLocation: {
    color: "#a06ecc",
    textAlign: "center"
  },
  greyBorderAll: {
    border: "2.3px solid lightgrey",
    borderTopLeftRadius: ".25rem"
  },
  greyBorderBottom: {
    borderBottom: "2.3px solid lightgrey"
  },

  tableData: {
    wordWrap: "break-word",
    // textAlign: "center",
    // verticalAlign: "middle",
    margin: "12px 5px",
    width: "100vw"
  },
  boxData: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "6%",
    margin: "0 5px"
  },
  buttonBorder: {
    border: "1px solid grey",
    color: "#a06ecc",
    backgroundColor: "transparent",
    borderRadius: ".25rem",
    marginLeft: 10
  }
};

class LocationsTable extends React.Component {
  state = {
    addBtnColor: "transparent",
    addBttnText: "Add"
  };

  addPartNewPartClick = () => {
    if (this.state.addBttnText === "Add") {
      this.props.toggleAddingPart();
      this.setState({
        addBttnText: "Done"
      });
    } else {
      this.props.toggleAddingPart();
      this.setState({
        addBttnText: "Add"
      });
    }

    if (this.state.addBtnColor === "transparent") {
      this.setState({
        addBtnColor: "Black"
      });
    } else {
      this.setState({
        addBtnColor: "transparent"
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* <NavButtons toggleLocateBtn={locationBtnColor} />
      <div id="searchFormDiv">
        <Form id="searchForm">
          <FormControl type="text" placeholder="G1" />
          <Button id="searchButton" >Search</Button>
          <Button id="deleteButton" >Edit</Button>
          <Button id="deleteButton" >Add</Button>


        </Form>
      </div> */}
        {/* 
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={modalClose}
      /> */}

        <div className="tableWrapper">
          <Table striped bordered hover responsive>
            <thead style={styles.greyBorderAll}>
              <tr>
                {/* <th>Picture</th> */}
                <th width="225px" style={{ textAlign: "center" }}>
                  <Tooltipped>Part Number</Tooltipped>
                </th>
                <th width="85px" style={{ textAlign: "center" }}>
                  <Tooltipped>Metal</Tooltipped>
                </th>
                <th width="225px" style={{ textAlign: "center" }}>
                  <Tooltipped> Description</Tooltipped>
                </th>
                <th width="85px" style={{ textAlign: "center" }}>
                  <Tooltipped>Weight</Tooltipped>
                </th>
                <th width="85px" style={{ textAlign: "center" }}>
                  <Tooltipped> Metal Cost</Tooltipped>
                </th>
                <th width="100px" style={{ textAlign: "center" }}>
                  <Tooltipped>Casting Labor </Tooltipped>
                </th>
                <th width="100px" style={{ textAlign: "center" }}>
                  <Tooltipped> Internal Labor</Tooltipped>
                </th>
                <th width="85px" style={{ textAlign: "center" }}>
                  <Tooltipped>Price </Tooltipped>
                </th>
                <th width="200px" style={{ textAlign: "center" }}>
                  <Tooltipped> Notes</Tooltipped>
                </th>
                <th width="110px" style={{ textAlign: "center", verticalAlign: "center" }}>
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Tooltipped> Qty In Box</Tooltipped>

                    <button
                      onClick={this.addPartNewPartClick}
                      style={{
                        color: "#bba3d0",
                        backgroundColor: this.state.addBtnColor,
                        borderColor: " #6e6e6e",
                        borderRadius: ".25rem",
                        fontWeight: "bolder",
                        
                      }}
                    >
                      {this.state.addBttnText}
                    </button>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody style={styles.greyBorderAll}>
              {this.props.location.map(item => {
                return (
                  <tr key={item.id} style={styles.greyBorderBottom}>
                    {/* <td>
                      <img
                        src="https://via.placeholder.com/35C/"
                        alt="placeHolder img"
                      />
                    </td> */}
                    {item.partnumber ? (
                      <td style={styles.tableData}>
                        <Link
                          style={styles.partLocation}
                          to={{
                            pathname: "/Parts",
                            state: {
                              partNumber: item.partnumber
                            }
                          }}
                        >
                          {" "}
                          <Tooltipped>{item.partnumber}</Tooltipped>
                        </Link>
                      </td>
                    ) : (
                        <td style={styles.tableData}> </td>
                      )}
                    <td style={styles.tableData}>
                      {item.material_purchased.metaltype
                        ? item.material_purchased.metaltype
                        : null}
                    </td>
                    <td style={styles.tableData}>
                      <Tooltipped>
                        {item.description ? item.description : null}
                      </Tooltipped>
                    </td>
                    <td style={styles.tableData}>
                      {item.material_purchased.weight
                        ? item.material_purchased.weight
                        : null}
                    </td>
                    <td style={styles.tableData}>
                      {item.material_purchased.metalcost
                        ? "$" + item.material_purchased.metalcost
                        : null}
                    </td>
                    <td style={styles.tableData}>
                      {item.material_purchased.castingcost
                        ? "$" + item.material_purchased.castingcost
                        : null}
                    </td>
                    <td style={styles.tableData}>
                      {item.material_purchased.internalcost
                        ? "$" + item.material_purchased.internalcost
                        : null}
                    </td>
                    <td style={styles.tableData}>
                      {item.wholesaleprice ? "$" + item.wholesaleprice : null}
                    </td>
                    <td style={styles.tableData}>
                      <Tooltipped>
                        {item.material_purchased.notes
                          ? item.material_purchased.notes
                          : null}
                      </Tooltipped>
                    </td>
                    <td style={styles.tableData}>
                      {item.material_inventory.quantitycurrent}
                      {/* {this.props.showInput ? <div style={{width: "1%"}}>" + " <input placeholder="QTY"/> <button onClick={this.props.cancelAddQTY}>Add</button> </div>: null} */}
                      <button
                        onClick={() =>
                          this.props.handleAddQTY(item.partnumber, item.id)
                        }
                        style={{
                          color: "#bba3d0",
                          borderColor: " #6e6e6e",
                          borderRadius: ".25rem",
                          fontWeight: "bolder",
                          float: "right"
                        }}
                      >
                        {"+"}
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </React.Fragment>
    );
  }
}

export default LocationsTable;
