import React from "react";

const styles = {
  addBtn: {
    borderRadius: "0.25rem",
    width: "12%",
    fontWeight: "bold",
    border: " #454d55 solid 1px",
    marginLeft: "2%",
    padding: "0.6%"
  },
  addBtnActive: {
    backgroundColor: "#bba3d0",
    color: "white"
  },
  addBtnDisabled: {
    backgroundColor: "#454d55",
    color: "lightgrey"
  }
};
const PartSearchInput = React.forwardRef((props, ref) => {
  const disabled = props.partNum.length < 2 || props.partQty <= 0;
  // props.suggestions.length === 0;
  return (
    <div id="searchForm">
      <input
        id="estPart"
        name="partNum"
        autoComplete="off"
        ref={props.inputFocusRef}
        value={props.partNum ? props.partNum : ""}
        onChange={props.handleInputChange}
        // placeholder="Scan a Part # or enter a Part # and select a suggestion"
        placeholder="Part #"
      />

      <input
        id="estQTY"
        placeholder="QTY"
        name="partQty"
        autoComplete="off"
        ref={props.qtyFocusRef}
        value={props.partQty ? props.partQty : ""}
        type="number"
        onChange={props.handleInputChange}
        placeholder="QTY"
      />
      <button
        style={
          disabled
            ? { ...styles.addBtn, ...styles.addBtnDisabled }
            : { ...styles.addBtn, ...styles.addBtnActive }
        }
        name="partSearch"
        autoComplete="off"
        disabled={disabled}
        onClick={props.handleAddPart}
      >
        ADD
      </button>
    </div>
  );
});
export default PartSearchInput;
