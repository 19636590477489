import React from "react";
import { Modal } from "react-bootstrap";

const styles = {
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly"
  },
  cancel: {
    borderRadius: "0.25rem",
    width: 140,
    color: "#bba3d0",
    fontWeight: "bold",
    border: "#454d55 solid 1px",
    padding: "0.6%"
  },
  delete: {
    borderRadius: "0.25rem",
    width: 140,
    color: "white",
    backgroundColor: "red",
    fontWeight: "bold",
    border: "#454d55 solid 1px",
    padding: "0.6%"
  }
};

export default function DeleteModal(props) {
  const { show, onHide, handleDelete, locationName, type } = props;
    const deletePart = (e) => {
      e.preventDefault();
      handleDelete();
    }
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete {type}?</Modal.Title>
      </Modal.Header>
      <Modal.Body
      // style={styles.modal}
      >
        <div style={styles.modalContainer}>
          <p>Are you sure you want to delete:</p>
          <h4 style={{ marginBottom: 30 }}>{locationName}</h4>
          <div style={styles.buttonContainer}>
            <button
              id="addExtraBttn"
              style={styles.delete}
              onClick={deletePart}
            >
              Delete
            </button>
            <button id="addExtraBttn" style={styles.cancel} onClick={onHide}>
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
