import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from 'react-router-dom'


class UpdateJobModal extends React.Component {


  render() {




    return (
      <div>
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered

        >


          <div variant="secondary" style={{ display: "flex", flexDirection: "column", padding: "5%" }}>
            <h4 style={{ textAlign: "center", color: "black" }}>{this.props.msg}</h4>
            <br />
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              {this.props.job_number ? 
              <Link
                style={{ color: "#bba3d0" }}
                to={{
                  pathname: "/jobInfo",
                  state: {
                    jobId: this.props.job_number
                  }
                }}
              ><Button style={{ backgroundColor: "#454d55", border: "none" }}>Go Back</Button></Link> :
              <Link
                style={{ color: "#bba3d0" }}
                to={{
                  pathname: "/searchLocation",
                  state: {
                    locationId: this.props.locationId
                  }
                }}
              ><Button style={{ backgroundColor: "#454d55", border: "none" }}>Go Back</Button></Link>
              }
            </div>
            </div>
        </Modal>

      </div>
        );
      }
    }
    
export default UpdateJobModal;