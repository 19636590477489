import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import QRCode from "qrcode.react";
import print from 'print-js';
import { Link } from "@material-ui/core";


class MyVerticallyCenteredModal extends React.Component {

  linkToTables = () => {

  }

  printBarCode = () => {
    print('QRDiv', 'html');
    this.props.onHide();
  }

  render() {
    return (
      <div>
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton >
            <Modal.Title id="contained-modal-title-vcenter" style={{ color: "#454d55", marginLeft: "35%" }}>
              Created Successfully
            </Modal.Title>
          </Modal.Header>
          <div id="QRDiv" style={{ display: "flex", justifyContent: "center" }}>
            <QRCode value={this.props.partnumberforqr.toString()}/>
            {/* {print('QRDiv', 'html')} */}
          </div>
          {/* <Modal.Body>
            <h4>Name of new</h4>
            <p>
              Print Barcode ||||| Go to created location</p>
          </Modal.Body> */}
          <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={this.printBarCode} style={{ backgroundColor: "#bba3d0", border: "none" }}>Print Barcode</Button>
            <Button onClick={this.props.onHide} style={{ backgroundColor: "#454d55", border: "none" }}>Go to Parts</Button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}

export default MyVerticallyCenteredModal;