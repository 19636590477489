import React from "react";
import axios from "axios";
import NavButtons from "../../navBtns";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import { FiEdit } from "react-icons/fi";

import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { endpoint } from "../../../utils/endpoint";
import Tooltipped from "../../tooltip/Tooltipped";

const styles = {
  columnTitle: {
    color: "#a06ecc",
    textAlign: "center",
    padding: "12px 5px"
  },
  partLocation: {
    color: "#a06ecc"
  },
  greyBorderAll: {
    border: "2.3px solid lightgrey",
    borderTopLeftRadius: ".25rem"
  },
  greyBorderBottom: {
    borderBottom: "2.3px solid lightgrey"
  },

  tableData: {
    wordWrap: "break-word",
    // textAlign: "center",
    verticalAlign: "middle"
  },
  boxData: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "6%",
    margin: "0 5px"
  },
  buttonBorder: {
    border: "1px solid grey",
    color: "#a06ecc",
    backgroundColor: "transparent",
    borderRadius: ".25rem"
  }
};

class locationsTable extends React.Component {
  state = {
    navBtnColor: "",
    partsData: [],
    modalShow: false,
    editButtons: false
  };

  componentDidMount() {
    if (this.props.location.state !== undefined) {
      axios
        .get(
          endpoint +
            `/searchAllPartsByNumberAndDesc/${this.props.location.state.partNumber}`
        )
        .then(response => {
          let searchResults = response.data;
          this.setState({
            partsData: searchResults
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    } else {
      this.grabAllParts();
    }
    this.setState({ navBtnColor: "#454d55" });
    window.addEventListener("keydown", this.partScannedFunction, false);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.partScannedFunction, false);
  }

  partScannedFunction = async event => {
    if (event.key === "Enter") {
      this.partInput.value = "";
    }
  };

  grabAllParts = () => {
    axios
      .get(endpoint + "/grabMaterial")
      .then(response => {
        let partsData = response.data;
        this.setState({
          partsData
        });
        console.log("STATE:", this.state.partsData);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  searchAllParts = e => {
    if (e.target.value.length) {
      axios
        .get(endpoint + `/searchAllPartsByNumberAndDesc/${e.target.value}`)
        .then(response => {
          let searchResults = response.data;

          this.setState({
            partsData: searchResults
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    } else {
      this.grabAllParts();
    }
  };

  addPartInputs = () => {};

  areYouSureDelete = () => {
    this.setState({ modalShow: true });
  };

  handleEditClick = () => {
    this.state.editButtons === false
      ? this.setState({
          editButtons: true
        })
      : this.setState({
          editButtons: false
        });
  };

  render() {

    return (
      <React.Fragment>
        <NavButtons togglePartsBtn={this.state.navBtnColor} />
        <div id="searchFormDiv">
          <Form id="searchForm">
            <FormControl
              type="text"
              placeholder="Search by part number or description"
              style={{
                width: "25%",
                margin: "0 auto",
                marginTop: "2%",
                border: "#dee2e6 solid 2px"
              }}
              onChange={this.searchAllParts}
              ref={input => {
                this.partInput = input;
              }}
            />
          </Form>
        </div>


        <div
          style={{
            width: "95%",
            margin: "0 auto",
            marginTop: "2%",
            borderRadius: ".25rem"
          }}
        >
          <Table
            striped
            bordered
            hover
            responsive
            className="rounded"
            size="md"
          >
            <thead style={styles.greyBorderAll}>
              <tr>
                <th width="225px" style={{ textAlign: "center" }}>
                  <Tooltipped>Location</Tooltipped>
                </th>
                {/* <th style={styles.columnTitle}>Picture</th> */}
                <th width="200px" style={{ textAlign: "center" }}>
                  <Tooltipped>Part Number</Tooltipped>
                </th>
                <th width="225px" style={{ textAlign: "center" }}>
                  <Tooltipped>Description</Tooltipped>
                </th>
                <th width="85px" style={{ textAlign: "center" }}>
                  <Tooltipped>Weight</Tooltipped>
                </th>
                <th width="85px" style={{ textAlign: "center" }}>
                  <Tooltipped>Metal</Tooltipped>
                </th>
                <th width="95px" style={{ textAlign: "center" }}>
                  <Tooltipped>Metal Cost</Tooltipped>
                </th>
                <th width="125px" style={{ textAlign: "center" }}>
                  <Tooltipped>Casting Labor</Tooltipped>
                </th>
                <th width="125px" style={{ textAlign: "center" }}>
                  <Tooltipped>Internal Labor</Tooltipped>
                </th>
                <th width="85px" style={{ textAlign: "center" }}>
                  <Tooltipped>Price</Tooltipped>
                </th>
                <th width="150px" style={{ textAlign: "center" }}>
                  <Tooltipped>Notes</Tooltipped>
                </th>
                <th width="100px" style={{ textAlign: "center" }}>
                  <Tooltipped>Qty In Box</Tooltipped>
                </th>
              </tr>
            </thead>
            <tbody style={styles.greyBorderAll}>
              {this.state.partsData.map(item => {
                return (
                  <tr key={item.id} style={styles.greyBorderBottom}>
                    <td style={styles.tableData}>
                      <Link
                        style={styles.partLocation}
                        to={{
                          pathname: "/searchLocation",
                          state: {
                            locationId: item.location ? item.location.id : null
                          }
                        }}
                      >
                        <Tooltipped>
                          {item.location ? item.location.location_title : null}{" "}
                        </Tooltipped>
                      </Link>
                    </td>
                    <td>
                      <Tooltipped>{item.partnumber}</Tooltipped>
                    </td>
                    <td style={styles.tableData}>
                      <Tooltipped>
                        {item.description ? item.description : null}
                      </Tooltipped>
                    </td>
                    <td style={styles.tableData}>
                      {item.material_purchased.weight &&
                      item.material_purchased.weight !== "0.000"
                        ? item.material_purchased.weight
                        : null}
                    </td>
                    <td style={styles.tableData}>
                      {item.material_purchased.metaltype
                        ? item.material_purchased.metaltype
                        : null}
                    </td>
                    <td style={styles.tableData}>
                      {item.material_purchased.metalcost
                        ? "$" + item.material_purchased.metalcost
                        : null}
                    </td>
                    <td style={styles.tableData}>
                      {" "}
                      {item.material_purchased.castingcost
                        ? "$" + item.material_purchased.castingcost
                        : null}
                    </td>
                    <td style={styles.tableData}>
                      {" "}
                      {item.material_purchased.internalcost
                        ? "$" + item.material_purchased.internalcost
                        : null}
                    </td>
                    <td style={styles.tableData}>
                      {" "}
                      {item.wholesaleprice ? "$" + item.wholesaleprice : null}
                    </td>
                    <td style={styles.tableData}>
                      <Tooltipped>{item.material_purchased.notes}</Tooltipped>
                    </td>
                    <div style={styles.boxData}>
                      {item.material_inventory.quantitycurrent}
                      <div className="editDiv">
                        <Link
                          to={{ pathname: "/editPart", state: { id: item.id } }}
                        >
                          <button style={styles.buttonBorder}>
                            <FiEdit />
                          </button>
                        </Link>
                      </div>
                    </div>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </React.Fragment>
    );
  }
}

export default locationsTable;
