import React from "react";
import { Table } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { FaTrashAlt } from "react-icons/fa";
import Tooltipped from "../tooltip/Tooltipped";
export default function PartTable(props) {
  const { handleRemovePart, toggleModal, parts } = props;

  if (parts.length === 0)
    return (
      <h4 style={{ textAlign: "center", color: "#454d55"}}>
        Add a part; or add a custom row to begin.
      </h4>
    );

  return (
    <Table striped bordered hover variant="dark" id="estimate-table">
      <thead>
        <tr>
          <th>
            <Tooltipped>Part Number</Tooltipped>
          </th>
          <th>
            <Tooltipped>Description</Tooltipped>
          </th>
          <th>
            <Tooltipped>Quantity</Tooltipped>
          </th>
          <th>
            <Tooltipped>Price</Tooltipped>
          </th>
        </tr>
      </thead>
      <tbody>
        {parts.map((part, index) => (
          <tr key={index}>
            <td>
              <Tooltipped>{part.partNum}</Tooltipped>
            </td>
            <td>
              <Tooltipped>{part.desc}</Tooltipped>
            </td>
            <td>{part.qty}</td>
            <td>
              {/* ${( */}
                {part.price}
              {/* * parseFloat(part.qty)).toFixed(2)} */}
              <div className="editDiv">
                <button
                  className="editButtons"
                  onClick={() => handleRemovePart(index)}
                >
                  <FaTrashAlt />
                </button>
                <button
                  className="editButtons"
                  onClick={() => toggleModal(part, index)}
                >
                  <FiEdit />
                </button>{" "}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
