import React from "react";
import NavButtons from "../../navBtns";
// import Button from "react-bootstrap/Button";
// import FormControl from "react-bootstrap/FormControl";
// import Form from "react-bootstrap/Form";
import { Form, FormControl, Button } from "react-bootstrap";
import RePrintModal from "../../modals/rePrintBarcodeModal";
import MustbeEmptyModal from "../../modals/mustBeEmptyModal";
import UpdateModal from "../../modals/updateLocationModal";
import DeleteModal from "../../modals/deleteModal/DeleteModal";
import { endpoint } from "../../../utils/endpoint";
import axios from "axios";

class createLocations extends React.Component {
  state = {
    navBtnColor: "",
    modalShow: false,
    mustBeEmptyModalShow: false,
    updateModalShow: false,
    newLocationName: "",
    // newLocationName: "",
    // locationType: this.props.location.state.locationId,
    locationType: "",
    locationId: "",
    locationType: "",
    errorMsg: "",
    deleteModalOpen: false,
    loading: true
  };

  componentDidMount() {
    console.log("this.props", this.props);
    if (!this.props.location.state) {
      console.log(" PUSH TO ");
      this.props.history.push("/searchLocation");
    } else {
      const {
        locationType,
        locationId,
        locationTitle
      } = this.props.location.state;
      this.setState({
        navBtnColor: "#454d55",
        locationType,
        locationId,
        locationTitle,
        newLocationName: locationTitle,
        loading: false
      });
    }
  }

  handleLocationInput = event => {
    let newLocationName = event.target.value;
    console.log(newLocationName, "name");
    this.setState({
      newLocationName
    });
  };

  handleLocationType = event => {
    let locationType = event.target.value;
    this.setState({
      locationType
    });
  };

  rePrintBarcode = () => {
    this.setState({
      modalShow: true
    });
  };

  deleteLocationCheck = () => {
    let URL;
    if (this.state.locationType === 1) {
      URL = endpoint + "/deleteJobLocation";
    } else {
      URL = endpoint + "/deletePartLocation";
    }
    axios
      .delete(URL, {
        data: {
          locationId: this.props.location.state.locationId
        }
      })
      .then(response => {
        if (response.data === "Location deleted") {
          this.props.history.push("/searchLocation");
        } else {
          this.setState({
            mustBeEmptyModalShow: true,
            errorMsg: response.data
          });
        }
      });
  };

  toggleDeleteModal = () => {
    this.setState({ deleteModalOpen: !this.state.deleteModalOpen });
  };

  updateLocation = () => {
    axios
      .put(endpoint + "/updateLocation", {
        newLocationTitle: this.state.newLocationName,
        locationId: this.props.location.state.locationId,
        locationType: this.state.locationType
      })
      .then(response => {
        this.setState({
          updateModalShow: true,
          errorMsg: "Updated Successfully"
        });
      });
  };

  modalClose = () =>
    this.setState({
      modalShow: false,
      mustBeEmptyModalShow: false,
      updateModalShow: false
    });
  render() {
    return (
      <React.Fragment>
        <NavButtons toggleBtn={this.state.navBtnColor} />

        {this.state.loading ? (
          <h1>Loading</h1>
        ) : (
          <>
            <div id="formBackground">
              <div id="locationInputDiv">
                <h2
                  style={{
                    borderLeft: "solid #bba3d0 2px",
                    paddingLeft: "1.5%",
                    marginBottom: 30
                  }}
                >
                  Edit Location
                </h2>
                <label className="locationLabel">Location name:</label>
                <FormControl
                  className="locationInput"
                  type="text"
                  placeholder="Ex: Samples"
                  onChange={this.handleLocationInput}
                  value={this.state.newLocationName}
                />

                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label className="locationLabel">
                    Location Type:
                  </Form.Label>
                  <Form.Control
                    as="select"
                    className="locationInput locationSelect"
                    value={this.state.locationType}
                    onChange={this.handleLocationType}
                  >
                    <option value={1}>Job</option>
                    <option value={2}>Parts</option>
                  </Form.Control>
                </Form.Group>

                {/* May need input and select list in a form div */}
              </div>
            </div>

            <Button
              variant="outline-secondary"
              id="createButton"
              onClick={this.updateLocation}
            >
              Update
            </Button>
            <Button
              variant="outline-secondary"
              className="editLocationButtons"
              onClick={this.rePrintBarcode}
            >
              {" "}
              Reprint QR
            </Button>
            <Button
              variant="outline-secondary"
              className="editLocationButtons"
              onClick={this.toggleDeleteModal}
              // onClick={this.deleteLocationCheck}
            >
              {" "}
              Delete
            </Button>
            <RePrintModal
              show={this.state.modalShow}
              onHide={this.modalClose}
              location={this.props.location.state.locationId}
            />

            <MustbeEmptyModal
              show={this.state.mustBeEmptyModalShow}
              onHide={this.modalClose}
              locationTitle={this.props.location.state.locationTitle}
              msg={this.state.errorMsg}
            />

            <UpdateModal
              show={this.state.updateModalShow}
              onHide={this.modalClose}
              locationTitle={this.state.newLocationName}
              msg={this.state.errorMsg}
              locationId={this.props.location.state.locationId}
            />

            <DeleteModal
              show={this.state.deleteModalOpen}
              onHide={this.toggleDeleteModal}
              handleDelete={this.deleteLocationCheck}
              locationName={this.state.newLocationName}
              type="Location"
            />
          </>
        )}
      </React.Fragment>
    );
  }
}

export default createLocations;
