
import React, { useState, useEffect } from 'react';
import { MDBDataTable } from 'mdbreact';
import format from 'date-fns/format';

const DataTable = (props) => {
  const [jobData, setJobData] = useState()

  const jobClicked = (jNum) => {
    const { history } = props;
    history.push({
      pathname: "/jobInfo",
      state: {
        jobId: jNum
      }
    });
  }

  useEffect(() => {
    const data = props.locationData.map(item => {
      item.jobTypeId = item.job_type.type
      item.updatedAt = format(item.updatedAt, "MM-DD-YYYY")
      item.clickEvent = () => jobClicked(item.job_number)

      return item
    })

    const sortedData = data.slice().sort((a, b) => b.updatedAt - a.updatedAt)

    setJobData(sortedData);

  }, [props.locationData])

  const data = {
    columns: [
      {
        label: 'Job Number',
        field: 'job_number',
        //sort: 'asc',
        width: 150
      },
      {
        label: 'Job Type',
        field: 'jobTypeId',
        //sort: 'asc',
        width: 150
      },
      {
        label: 'Last date scanned',
        field: 'updatedAt',
        //sort: 'asc',
        width: 150
      },
    ],
    rows: jobData
  };

  return (
    <div>
      {console.log('DATT WHYYY', data)}

      <MDBDataTable
        style={{ zindex: '-1' }}
        //btn
        striped
        sortable
        bordered
        hover
        data={data}
        scrollY
        entriesOptions={[10, 15, 50, 100, 200, 500, 1000]}
        entriesLabel={"Currently displaying"}
        infoLabel={['Displaying', 'to', 'of', 'Jobs']}
        maxHeight='100vh'
        pagesAmount={10}
        entries={15}
        searching={true}
        searchLabel='Search Jobs'
        searching={false}
      //dark
      />
    </div>

  );
}

export default DataTable;