import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import QRCode from "qrcode.react";
import print from 'print-js';
import { Link } from "react-router-dom";

class MyVerticallyCenteredModal extends React.Component {
  printBarCode = () => {
    print('QRDiv', 'html');
    this.props.onHide();
  }
  render() {
    return (
      <div>
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton >
            <Modal.Title id="contained-modal-title-vcenter" style={{ color: "#454d55", marginLeft: "35%" }}>
              Created Successfully
            </Modal.Title>
          </Modal.Header>
          <div id="QRDiv" style={{ display: "flex", justifyContent: "center" }}>
            <QRCode value={this.props.jobNumForQr} />
          </div>
          <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={this.printBarCode} style={{ backgroundColor: "#bba3d0", border: "none" }}>Print Barcode</Button>
            <Link to={{
              pathname: "/jobInfo", state: {
                jobId: this.props.jobNumForQr
              }
            }}><Button onClick={this.props.onHide} style={{ backgroundColor: "#454d55", border: "none" }}>Go to Job</Button></Link>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default MyVerticallyCenteredModal;