import React from 'react';
import { MDBDataTable } from 'mdbreact';
import format from 'date-fns/format';

const DataTable = (props) => {
    // const returnJobType = jobTypeId => {
    //     let jobTypeString;
    //     switch (jobTypeId) {
    //       case 1:
    //         jobTypeString = "Repair";
    //         break;
    //       case 2:
    //         jobTypeString = "Custom";
    //         break;
    //       case 3:
    //         jobTypeString = "Sample";
    //         break;
    //       case 4:
    //         jobTypeString = "Part";
    //         break;
    //       case 5:
    //         jobTypeString = "Finished";
    //         break;
    //       default:
    //         jobTypeString = null;
    //         break;
    //     }
    //     return jobTypeString;
    //   };

    const convertToType = num => {
        switch (num) {
            case 1:
                return "Job";
            case 2:
                return "Part";
        }
    };

    const locationClicked = (text) => {
        console.log(text)
    }

    const tableData = props.locationInfo.map(item => {
        item.location_title = item.location_title
        item.locationTypeId = convertToType(item.locationTypeId)
        item.clickEvent = () => props.locationClicked(item.id)

        return item
    });

    const data = {
        columns: [
            {
                label: 'Name',
                field: 'location_title',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Type',
                field: 'locationTypeId',
                sort: 'asc',
                width: 150
            },
        ],
        rows: tableData


    };

    return (
        <MDBDataTable
            btn
            striped
            sortable
            bordered
            hover
            data={data}
            scrollY
            entriesOptions={[10, 15, 50, 100, 200, 500, 1000]}
            entriesLabel={"Currently displaying"}
            infoLabel={['Displaying', 'to', 'of', 'locations']}
            maxHeight='100vh'
            pagesAmount={10}
            entries={15}
            searching={true}
            searchLabel='Search locations'

        />
    );
}

export default DataTable;