import React from "react";
import NavButtons from "../../navBtns";
import { Button, Form } from "react-bootstrap";
import DeleteModal from "../../modals/deleteModal/DeleteModal";

import RePrintModal from "../../modals/rePrintBarcodeModal";
import "./style.css";
import axios from "axios";
import EditSample from "./editSample";
import UpdateModal from "../../modals/updateLocationModal";
import { endpoint } from "../../../utils/endpoint";
class createLocations extends React.Component {
  state = {
    navBtnColor: "",
    modalShow: false,
    modalUpdateShow: false,
    locationName: "",
    jobType: "Job",

    style: "",
    jobNumber: "",
    jobTypeId: "",
    dateStarted: "",
    notes: "",
    finishDate: "",
    type: "",
    description: "",
    stone: "",
    stonePrice: "",
    band: "",
    errMsg: "",
    loading: true,
    deleteModalOpen: false
  };

  componentDidMount() {
    if (this.props.location.state) {
      const { jobData } = this.props.location.state;
      this.setState({
        //   //   navBtnColor: "#454d55",
        style: jobData.style,
        //   //   // jobData: jobData,
        jobNumber: jobData.job_number,
        jobTypeId: "" + jobData.jobTypeId,
        dateStarted: jobData.date_started,
        finishDate: jobData.finish_date,
        notes: jobData.notes,
        //   // type: jobData.type,
        description: jobData.description || "",
        stone: jobData.stone || "",
        stonePrice: jobData.stone_price || "",
        band: jobData.band || "",
        loading: false
      });
    } else {
      // redirect if no job info sent via props
      this.props.history.push("/jobInfo");
    }
  }

  handleLocationInput = event => {
    let locationName = event.target.value;
    this.setState({
      locationName
    });
  };

  handleUpdateInput = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  submitLocation = () => {
    // this.createNewLocation();
    this.setState({ modalShow: true });
  };

  updateJob = () => {
    if (this.state.jobTypeId === "3") {
      axios
        .put(endpoint + "/updateSampleJobOrder", {
          id: this.props.location.state.jobId,
          dateStarted: this.state.dateStarted,
          finishDate: this.state.finishDate,
          // jobNumber: this.state.jobNumber,
          locationId: this.state.locationId,
          jobTypeId: this.state.jobTypeId,
          notes: this.state.notes,
          jobTypeText: this.state.type,
          description: this.state.description,
          stone: this.state.stone,
          stonePrice: this.state.stonePrice ? this.state.stonePrice : null,
          band: this.state.band,
          style: this.state.style
        })
        .then(response => {
          this.setState({
            modalUpdateShow: true,
            errMsg: "Updated Successfully"
          });
          console.log(response);
        })
        .catch(function(error) {
          console.log(error);
        });
    } else {
      axios
        .put(endpoint + "/updateJobOrder", {
          id: this.props.location.state.jobId,
          // jobNumber: this.state.jobNumber,
          jobTypeId: this.state.jobTypeId,
          dateStarted: this.state.dateStarted,
          finishDate: this.state.finishDate,
          notes: this.state.notes
        })
        .then(response => {
          console.log(response);
          this.setState({
            modalUpdateShow: true,
            errMsg: "Updated Successfully"
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  deleteJob = () => {
    axios
      .delete(endpoint + "/deleteJobOrder", {
        data: {
          jobOrderId: this.props.location.state.jobData.id
        }
      })
      .then(response => {
        console.log("DELETE?", response);
        if (response.data === "Job deleted") {
          this.props.history.push("/jobInfo");
        } else {
          this.setState({
            modalUpdateShow: true,
            deleteModalOpen: false,
            errMsg: response.data
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  toggleDeleteModal = () => {
    this.setState({ deleteModalOpen: !this.state.deleteModalOpen });
  };

  modalClose = () =>
    this.setState({ modalShow: false, modalUpdateShow: false });
  render() {
    return (
      <div>
        <NavButtons toggleBtn={this.state.navBtnColor} />
        {!this.state.loading ? (
          <div>
            <div className="containerDiv">
              <h2
                style={{
                  borderLeft: "solid #bba3d0 2px",
                  paddingLeft: "1.5%",
                  marginBottom: 30
                }}
              >
                Edit Job
              </h2>
              <div className="inputDivOne">
                <div className="dateInputDiv">
                  <label className="jobsLabel">Date Started</label>
                  <input
                    className="dateInputBox jobsInput"
                    type="date"
                    value={this.state.dateStarted}
                    name="dateStarted"
                    autoComplete="off"
                    onChange={this.handleUpdateInput}
                  ></input>
                </div>
                <div className="dateInputDiv">
                  <label className="jobsLabel">Estimated Date Complete</label>
                  <input
                    className="dateInputBox jobsInput"
                    type="date"
                    value={this.state.finishDate}
                    name="finishDate"
                    autoComplete="off"
                    onChange={this.handleUpdateInput}
                  ></input>
                </div>
              </div>

              <div className="inputDivOne">
                <div className="jobInputDiv">
                  <label className="jobsLabel">Job Number</label>
                  <Form.Control
                    className="jobsInput"
                    type="text"
                    placeholder="0001"
                    onChange={this.handleUpdateInput}
                    value={this.state.jobNumber}
                    disabled="true"
                    name="jobNumber"
                    autoComplete="off"
                  />
                </div>

                <div className="jobInputDiv">
                  <label className="jobsLabel ">Job Type</label>
                  <Form.Group
                    controlId="exampleForm.ControlSelect1"
                    className="test"
                  >
                    <Form.Control
                      name="jobTypeId"
                      as="select"
                      className="jobsInput"
                      defaultValue="Job"
                      style={{ border: "solid 2px #bba3d0" }}
                      onChange={this.handleUpdateInput}
                      value={this.state.jobTypeId}
                    >
                      <option value="1">Repair</option>
                      <option value="2">Custom</option>
                      <option value="3">Sample</option>
                      <option value="4">Part</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>

              <div className="inputDivOne">
                <div className="jobInputDiv">
                  <label className="jobsLabel">Notes</label>
                  <Form.Control
                    className="jobsInput"
                    type="text"
                    name="notes"
                    autoComplete="off"
                    value={this.state.notes}
                    onChange={this.handleUpdateInput}
                  />
                </div>
              </div>
              <div>
                {this.state.jobTypeId === "3" ? (
                  <EditSample
                    sampleData={{
                      style: this.state.style,
                      description: this.state.description,
                      stone: this.state.stone,
                      stonePrice: this.state.stonePrice,
                      band: this.state.band
                    }}
                    handleSampleUpdate={this.handleUpdateInput}
                  />
                ) : null}
              </div>
            </div>

            <div id="editButtonDiv">
              <Button
                variant="outline-secondary"
                id="updateJobButton"
                onClick={this.updateJob}
              >
                Update
              </Button>
              <Button
                variant="outline-secondary"
                id="editJobButton"
                onClick={this.toggleDeleteModal}
                style={{ marginLeft: "5%" }}
              >
                Delete
              </Button>
              <DeleteModal
                show={this.state.deleteModalOpen}
                onHide={this.toggleDeleteModal}
                handleDelete={this.deleteJob}
                locationName={`${this.state.jobNumber} ${
                  this.state.description ? " - " + this.state.description : ""
                } `}
                type="Job"
              />

              <Button
                variant="outline-secondary"
                id="editJobButton"
                onClick={this.submitLocation}
                style={{ marginLeft: "5%" }}
              >
                Reprint QR
              </Button>

              <RePrintModal
                show={this.state.modalShow}
                onHide={this.modalClose}
                jobId={this.props.location.state.jobData.job_number}
              />

              <UpdateModal
                show={this.state.modalUpdateShow}
                onHide={this.modalClose}
                msg={this.state.errMsg}
                job_number={this.props.location.state.jobData.job_number}
              />
            </div>
          </div>
        ) : (
          <h4>Loading...</h4>
        )}
      </div>
    );
  }
}

export default createLocations;

//     {/* <DeleteModal
//   show={true}
//   onHide={this.toggleDeleteModal}
//   deleteTitle={null}
//   locationName={"Job to delete"}
//   type="Job"
// /> */}
