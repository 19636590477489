import React, { useEffect } from "react";
import Background from './imgs/example.jpg';
import { GoogleLogin } from 'react-google-login';


const LoginPage = props => {
    const responseGoogle = async (response) => {
        if (response.googleId === '115735292339425661332') {
            await localStorage.setItem('jjHint', "true");
            props.history.push("/main");
        } else {
            props.history.push("/");
        }
    }
    
    return (
        <React.Fragment>
            <div style={{ display: 'flex', }}>
                <div style={{
                    width: "50%", backgroundImage: `url(${Background})`, height: '100vh', backgroundSize: "cover",
                    backgroundPosition: "center", borderRight: '2px solid #454d55',
                }} >
                </div>
                <div style={{ display: 'flex', width: "49%", justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "column", border: 'solid 2px #454d55', borderRadius: '.55rem', padding: "8%", color: "#454d55" }}>
                        <h1 style={{borderBottom: 'solid 2px #bba3d0'}}>Welcome Back!</h1>
                        <br/>
                        <GoogleLogin
                            clientId="969029110266-ibdd08ts7olcfq2ua6recqau4op0gd4r.apps.googleusercontent.com"
                            buttonText="Jamie Joseph Google Login"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                        />
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
};

export default LoginPage;
