import React from "react";
import FormControl from "react-bootstrap/FormControl";
import "./style.css";

const SampleInputs = props => {
  return (
    <div>
      <div className="inputDivOne">
        <div className="jobInputDiv">
          <label className="jobsLabel">Style</label>
          <FormControl
            className="dateInputBox jobsInput"
            type="text"
            name="style"
            autoComplete="off"
            placeholder=""
            onChange={props.handleSampleUpdate}
            value={props.sampleData.style}
          />
        </div>

        <div className="jobInputDiv">
          <label className="jobsLabel">Description</label>
          <FormControl
            className="dateInputBox jobsInput"
            type="text"
            placeholder=""
            name="description"
            autoComplete="off"
            onChange={props.handleSampleUpdate}
            value={props.sampleData.description}
          />
        </div>
      </div>

      <div className="inputDivOne">
        <div className="jobInputDiv">
          <label className="jobsLabel">Stone</label>
          <FormControl
            className="dateInputBox jobsInput"
            type="text"
            placeholder=""
            name="stone"
            autoComplete="off"
            onChange={props.handleSampleUpdate}
            value={props.sampleData.stone}
          />
        </div>

        <div className="jobInputDiv">
          <label className="jobsLabel">Stone Price</label>
          <FormControl
            className="dateInputBox jobsInput"
            type="text"
            placeholder=""
            name="stonePrice"
            autoComplete="off"
            onChange={props.handleSampleUpdate}
            value={props.sampleData.stonePrice}
          />
        </div>
      </div>

      <div className="jobInputDiv">
        <label className="jobsLabel">Band</label>
        <FormControl
          className="dateInputBox jobsInput"
          type="text"
          placeholder=""
          name="band"
          autoComplete="off"
          onChange={props.handleSampleUpdate}
          value={props.sampleData.band}
        />
      </div>
    </div>
  );
};

export default SampleInputs;
