import React from 'react';
import "./style.css";
import { Link } from "react-router-dom";
import { GoogleLogout } from 'react-google-login';

const navBar = () => {
    const logout = async () => {
        await localStorage.removeItem('jjHint');
    }

    return (
        <React.Fragment>
            <div className="navDiv">
                <Link to="/main" id="logoImg"><div >JJ-<span className="purpLetter">P</span>ower<span className="purpLetter">D</span>ata</div></Link>
                <div>
                    <Link to='/'>
                        <GoogleLogout
                            clientId="969029110266-ibdd08ts7olcfq2ua6recqau4op0gd4r.apps.googleusercontent.com"
                            buttonText="Logout"
                            onLogoutSuccess={logout}
                        >
                        </GoogleLogout>
                    </Link>
                </div>
            </div>
        </React.Fragment>
    );


}

export default navBar;