import React from "react";
import NavBar from "../navBar";
import "./style.css";
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link }from "react-router-dom";

function buttonNav(props) {

  return (
    <div id="wrapper">
    
      <NavBar />
      <div id="navBtnWrapper">
        <Dropdown id="locationButtonWrapper" style={{backgroundColor: props.toggleLocateBtn || props.toggleBtn}}>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="locationButton">
            Locations
          </Dropdown.Toggle>
          <Dropdown.Menu>
          <Dropdown.Item href="#/searchLocation">Search</Dropdown.Item>
            <hr/>
            <Dropdown.Item href="#/createLocation" >Create New</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown id="locationButtonWrapper" style={{backgroundColor: props.toggleJobsBtn}}>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="locationButton">
            Jobs
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#/jobInfo">Job Info</Dropdown.Item>
            <hr/>
            <Dropdown.Item href="#/jobsList" >List</Dropdown.Item>
            
            
            <hr/>
            <Dropdown.Item  href="#/jobAdd">Create New</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* <Link to="/partsLocation" className="linkAroundBttn"><Button className="navBtn" variant="outline-secondary">Parts</Button></Link> */}
        <Dropdown id="locationButtonWrapper" style={{backgroundColor: props.togglePartsBtn}}>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="locationButton">
            Parts
          </Dropdown.Toggle>
          <Dropdown.Menu>
          <Dropdown.Item href="#/Parts">Search</Dropdown.Item>
            <hr/>
            <Dropdown.Item href="#/addParts">Create New</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Link  to="/estimates" className="linkAroundBttn"><Button className="navBtn" variant="outline-secondary" style={{backgroundColor: props.toggleEstButton}}>Estimate</Button></Link>
      </div>
    </div>
  );


}

export default buttonNav;